import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const Android12Switch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      "& .MuiSwitch-thumb:before": {},
    },
  },
  "& .MuiSwitch-thumb": {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.common.white,
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    opacity: 1,
  },
}));

// MSwitch component
const MSwitch = ({ checked, disabled, onClick, onChange, ...props }) => {
  return (
    <Android12Switch
      checked={checked}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange}
      inputProps={{ "aria-label": "MSwitch controlled" }}
      {...props}
    />
  );
};

export default MSwitch;
