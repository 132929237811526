import React from "react";
import Box from "@mui/material/Box";
import AutomationChatCard from "./automation-chat-card";
import TextChatCard from "./text-chat-card";

const AssistantChatCard = React.memo(
  ({ payload, onSave, onDelete }) => {
    const renderCard = () => {
      switch (payload.type) {
        case "AUTOMATION":
          return (
            <AutomationChatCard
              payload={payload}
              onSave={onSave}
              onDelete={onDelete}
            />
          );
        case "TEXT":
          return <TextChatCard message={payload.content} data={payload} />;
        case "LLM_RES":
        case "AGENT_INFO":
          return <TextChatCard message={payload.content} data={payload} />;
        // case 'LLM_REQ':
        //     return <TextChatCard message={payload.content} data={payload}/>;
        default:
          return <></>;
      }
    };

    return (
      <Box display="flex" justifyContent="flex-start" mb={1}>
        {renderCard()}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.payload === nextProps.payload;
  }
);

AssistantChatCard.displayName = "AssistantChatCard";

export default AssistantChatCard;
