import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const UserChatCard = React.memo(({ text }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="flex-end" mb={1}>
      <Box
        sx={{
          maxWidth: "80%",
          backgroundColor: theme.palette.background.c1,
          borderRadius: "12px", // Adjust the value to control the roundness of the corners
          padding: "8px",
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
});

UserChatCard.displayName = "UserChatCard";

export default UserChatCard;
