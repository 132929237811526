import { DEV_AUTH_DOMAIN, PALLAS_CAT, USER_AUTH_DOMAIN } from "./config";

const USER_AUTH_CONFIG = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_keK5Iwm9p",
    userPoolWebClientId: process.env.REACT_APP_USER_COGNITO_CLIENT_ID,
    oauth: {
      domain: USER_AUTH_DOMAIN,
      scope: ["openid", "email"],
      redirectSignIn: `${PALLAS_CAT}/user-login-callback`,
      redirectSignOut: `${PALLAS_CAT}/user-logout`,
      responseType: "code",
    },
  },
};

const DEV_AUTH_CONFIG = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_Ximo8YJvC",
    userPoolWebClientId: process.env.REACT_APP_DEV_COGNITO_CLIENT_ID,
    oauth: {
      domain: DEV_AUTH_DOMAIN,
      scope: ["openid", "email"],
      redirectSignIn: `${PALLAS_CAT}/developer-login-callback`,
      redirectSignOut: `${PALLAS_CAT}/developer-logout`,
      responseType: "code",
    },
  },
};

export { USER_AUTH_CONFIG, DEV_AUTH_CONFIG };
