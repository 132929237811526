import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import "./markdown.css";

const Markdown = ({ children, components }) => {
  return (
    <div className="markdown-container">
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]} // Enables raw HTML
        remarkPlugins={[remarkGfm]} // Enables GitHub flavored markdown
        components={components}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
