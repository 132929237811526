import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
} from "@mui/material";

const EditGroupDialog = ({
  open,
  onClose,
  onSave,
  groupName,
  setGroupName,
  entities,
  selectedEntities,
  onToggleEntity,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{groupName ? "Edit Group" : "Create Group"}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Typography variant="subtitle1">Entities</Typography>
        <List sx={{ maxHeight: 300, overflow: "auto" }}>
          {entities.map((entity) => (
            <ListItem key={`${entity.integration}-${entity.id}`}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedEntities.includes(
                    `${entity.integration}-${entity.id}`
                  )}
                  onChange={() =>
                    onToggleEntity(`${entity.integration}-${entity.id}`)
                  }
                />
              </ListItemIcon>
              <ListItemText primary={entity.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupDialog;
