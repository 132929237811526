import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { DEV_AUTH_CONFIG } from "../aws-config";

const DeveloperLogout = () => {
  const history = useHistory();

  useEffect(() => {
    Amplify.configure(DEV_AUTH_CONFIG);
    history.push("/setting");
  }, [history]);

  return (
    <div>
      <h1>Developer logged out.</h1>
      <p>Redirecting...</p>
    </div>
  );
};

export default DeveloperLogout;
