import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash/debounce";
import { triggerWidgetEvent, updateWidgetData } from "./dashboard-service";
import { useWebSocket } from "../ws/websocket";
import { useToast } from "../common/toast";
import Button1Widget from "./widget/button-1";
import Number1Widget from "./widget/number-1";
import NumberUnit1Widget from "./widget/number-uint-1";
import Toggle1Widget from "./widget/toggle-1";
import Select1Widget from "./widget/select-1";
import MultiSelect1Widget from "./widget/multi-select-1";
import Text1Widget from "./widget/text-1";
import Time1Widget from "./widget/time-1";
import Date1Widget from "./widget/date-1";
import DateTime1Widget from "./widget/date-time-1";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardCard = React.memo(
  ({ card }) => {
    const theme = useTheme();
    const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
    const { successToast, errorToast } = useToast();
    const [width, setWidth] = useState(1);
    const [layouts, setLayouts] = useState(null);

    const getWidget = (widget) => {
      switch (widget.skin) {
        case "default.title1":
          return (
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                fontSize: "1rem",
              }}
            >
              {widget.title.title}
            </Typography>
          );
        case "default.button":
          return (
            <Button1Widget
              widget={widget}
              onValueUpdate={handleTriggerWidgetEvent}
            />
          );
        case "default.toggle":
          return (
            <Toggle1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.number":
          return (
            <Number1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.select":
          return (
            <Select1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.multiSelect":
          return (
            <MultiSelect1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.text":
          return (
            <Text1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.time":
          return (
            <Time1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.date":
          return (
            <Date1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.timestamp":
          return (
            <DateTime1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );
        case "default.numberUnit":
          return (
            <NumberUnit1Widget
              widget={widget}
              onValueUpdate={handleWidgetValueChange}
            />
          );

        default:
          return <div>Unknown skin: {widget.skin}</div>;
      }
    };

    useEffect(() => {
      setWidth(Math.max(...card.widgets.map((widget) => widget.layout.w)));
      const lo = card.widgets.map((w) => {
        return {
          i: w.id,
          x: w.layout.x,
          y: w.layout.y,
          w: w.layout.w,
          h: w.layout.h,
        };
      });
      setLayouts(lo);

      return () => {};
    }, [card]);

    const handleWidgetValueChange = (newWidget) => {
      debouncedUpdate(JSON.parse(JSON.stringify(newWidget)));
    };

    const debouncedUpdate = debounce((updatedWidget) => {
      handleUpdateWidgetValue(updatedWidget);
    }, 500);

    const handleUpdateWidgetValue = async (updatedWidget) => {
      console.log(updatedWidget);
      const resp = await updateWidgetData(sendAndWaitWs, updatedWidget);
      if (!resp) {
        errorToast("Error!");
      }
    };

    const handleTriggerWidgetEvent = async (widgetId) => {
      const resp = await triggerWidgetEvent(sendAndWaitWs, widgetId);
      if (!resp) {
        errorToast("Error!");
      }
    };

    return (
      <>
        {layouts && (
          <ResponsiveGridLayout
            className="layout"
            layouts={{ xxs: layouts }}
            breakpoints={{ xxs: 0 }}
            cols={{ xxs: width }}
            rowHeight={30}
            compactType="vertical"
            preventCollision={false}
            isDraggable={false}
            isResizable={false}
            margin={[0, 0]}
          >
            {card.widgets.map((widget) => (
              <div key={widget.id}>
                <>{getWidget(widget)}</>
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.card === nextProps.card;
  }
);

export default DashboardCard;

DashboardCard.displayName = "DashboardCard";
