import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";

const UserLogout = () => {
  const history = useHistory();

  useEffect(() => {
    Amplify.configure(USER_AUTH_CONFIG);
    history.push("/user-login");
  }, [history]);

  return (
    <div>
      <h1>User logged out.</h1>
      <p>Redirecting...</p>
    </div>
  );
};

export default UserLogout;
