export const complete = async (
  apiCall,
  scopeId,
  untrusted,
  instruction,
  debug
) => {
  const request = {
    scopeId,
    untrusted,
    instruction,
    script: null,
    allowBadValue: true,
    debug,
  };
  const jsonData = await apiCall("/assistant/complete", "POST", request);
  if (!jsonData || jsonData.error) {
    console.error(
      "Error completing assistant task:",
      jsonData ? jsonData.error : "Unknown error"
    );
    return null;
  }
  return jsonData;
};
