import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import ErrorIcon from "@mui/icons-material/Error";
import {
  disableAutomation,
  enableAutomation,
  listAutomations,
} from "./automation-service";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import AutomationFilter from "./automation-filter";
import MSwitch from "../common/switch";

const AutomationListView = ({ showFilter = false, setShowFilter }) => {
  const history = useHistory();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [automations, setAutomations] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteAutomationId, setDeleteAutomationId] = useState(null);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [sortBy, setSortBy] = useState("alphabet");
  const [filterText, setFilterText] = useState("");
  const [isShowFilter, setIsShowFilter] = useState(showFilter);

  useEffect(() => {
    setIsShowFilter(showFilter);
  }, [showFilter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleListAutomations();
  }, []);

  const sortedAutomations = automations
    .filter((a) => a.name.toLowerCase().includes(filterText.toLowerCase()))
    .sort((a, b) => {
      if (sortBy === "time") {
        const aTimestamp = a.timestamp
          ? dayjs(a.timestamp).valueOf()
          : Number.PASITIVE_INFINTY;
        const bTimestamp = b.timestamp
          ? dayjs(b.timestamp).valueOf()
          : Number.PASITIVE_INFINTY;
        return bTimestamp - aTimestamp;
      } else if (sortBy === "alphabet") {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName); //sort alphabetically
      }
    });

  const handleListAutomations = async () => {
    setLoading(true);
    const resp = await listAutomations(apiCall, userInfo.activeScope.id);
    if (resp) {
      const recurringAutomations = resp["automations"].filter(
        (automation) => automation.type === "RECURRING"
      );
      setAutomations(recurringAutomations);
      setLoading(false);
    }
  };

  const openOverview = (data) => {
    history.push(`/automation/overview?id=${data.id}`);
  };

  const handleEnableDisable = async (event, enableDisable, automationId) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Enabled.");
        const na = [...automations];
        na.filter((a) => a.id === automationId).forEach(
          (a) => (a.enabled = enableDisable)
        );
        setAutomations(na);
      } else {
        errorToast("Error to enable the automation.");
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Disabled.");
        const na = [...automations];
        na.filter((a) => a.id === automationId).forEach(
          (a) => (a.enabled = enableDisable)
        );
        setAutomations(na);
      } else {
        errorToast("Error to disable the automation.");
      }
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ flexGrow: 1, m: 2, pb: 12 }}>
          {isShowFilter && (
            <AutomationFilter
              sortBy={sortBy}
              setSortBy={setSortBy}
              setFilterText={setFilterText}
              setShowFilter={setShowFilter}
            />
          )}
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            columns={{ xs: 2, sm: 4, md: 12 }}
          >
            {sortedAutomations.map((automation, index) => (
              <Grid item xs={2} sm={2} md={4} key={index}>
                <Card elevation={0}>
                  <CardActionArea
                    onClick={() => openOverview(automation)}
                    style={{
                      backgroundColor: theme.palette.background.card,
                    }}
                  >
                    <CardContent sx={{ padding: "8px" }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          gutterBottom
                          variant="body1"
                          component="div"
                          sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "4.5em",
                            marginBottom: "0px",
                          }}
                        >
                          {automation.name}
                        </Typography>
                        {!automation.runnable ? (
                          <ErrorIcon
                            style={{
                              color: "red",
                              marginRight: "15px",
                              marginLeft: "15px",
                            }}
                          />
                        ) : (
                          <MSwitch
                            checked={automation.enabled}
                            disabled={!automation.runnable}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e, b) =>
                              handleEnableDisable(e, b, automation.id)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      </Box>
                      {automation.timestamp !== 0 && (
                        <Box>
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              marginTop: "5px",
                              color: theme.palette.text.gray,
                            }}
                          >
                            {customFormat(
                              dayjs(automation.timestamp),
                              currentTime
                            )}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AutomationListView;
