export const listIntegrations = async (apiCall, scopeId, parentId) => {
  if (parentId) {
    const response = await apiCall(
      `/integration/list?scope=${scopeId}&abstract=${parentId}`,
      "GET"
    );
    return response;
  } else {
    const response = await apiCall(`/integration/list?scope=${scopeId}`, "GET");
    return response;
  }
};

export const listEntities = async (apiCall, scopeId, integrationId) => {
  const response = await apiCall(
    `/integration/entity/list?scope=${scopeId}&integration=${integrationId}`,
    "GET"
  );
  return response;
};

export const getIntegration = async (apiCall, scopeId, integrationId) => {
  const response = await apiCall(
    `/integration/get?scope=${scopeId}&integration=${integrationId}`,
    "GET"
  );
  return response;
};

export const listGroups = async (apiCall, scopeId, integrationId) => {
  const response = await apiCall(
    `/integration/group/list?scope=${scopeId}&integration=${integrationId}`,
    "GET"
  );
  return response;
};

export const listProviders = async (apiCall, scopeId, integrationId) => {
  const response = await apiCall(
    `/integration/provider/list?scope=${scopeId}&integration=${integrationId}`,
    "GET"
  );
  return response;
};

export const installIntegration = async (apiCall, scope, integration) => {
  const req = { scope, integration };
  const response = await apiCall(`/integration/install`, "POST", req);
  return response && response.success;
};

export const saveGroup = async (apiCall, group) => {
  const response = await apiCall(`/integration/group/save`, "POST", group);
  return response && response.success;
};

export const connectIntegration = async (apiCall, scopeId, integration) => {
  const request = { scopeId, integration };
  return await apiCall("/integration/connect", "POST", request);
};
