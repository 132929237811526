import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import {
  developerEmailState,
  developerIdTokenState,
  linkOrgIdState,
  linkOrgUserIdState,
  originalPathState,
  platformState,
  userIdTokenState,
  userInfoState,
} from "../global-state";
import { useBackdrop } from "../common/backdrop";
import { Amplify, Auth } from "aws-amplify";
import { DEV_AUTH_CONFIG, USER_AUTH_CONFIG } from "../aws-config";
import { getLinkedUser, getUserInfo } from "../setting/setting-service";
import { useHistory, useLocation } from "react-router-dom";
import useApiCall from "../common/api-call";

export const useAuthCheck = () => {
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const [userIdToken, setUserIdToken] = useRecoilState(userIdTokenState);
  const [linkOrgId, setLinkOrgId] = useRecoilState(linkOrgIdState);
  const [linkOrgUserId, setLinkOrgUserId] = useRecoilState(linkOrgUserIdState);
  const [platform, setPlatform] = useRecoilState(platformState);
  const [developerEmail, setDeveloperEmail] =
    useRecoilState(developerEmailState);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { apiCall } = useApiCall();
  const location = useLocation();
  const history = useHistory();

  const [userAuthState, setUserAuthState] = useState(false);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const checkUserFromApp = async () => {
    const resp = await getUserInfo(apiCall, userInfoRef.current);
    if (resp) {
      setUserInfo(resp);
      setUserAuthState(true);
      closeBackdrop();
    } else {
      setUserAuthState(false);
      openBackdrop("Cannot reach the server. Please try again later.");
    }
  };

  const checkUserFromMLink = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setUserIdToken(idToken);
      const resp = await getLinkedUser(
        apiCall,
        linkOrgId,
        linkOrgUserId,
        idToken
      );
      if (resp) {
        if (resp.linked) {
          setUserInfo(resp.userInfo);
          setUserAuthState(true);
          closeBackdrop();
        } else {
          setUserAuthState(false);
          history.push("/no-link");
        }
      } else {
        setUserAuthState(false);
        openBackdrop("Cannot reach the server. Please try again later.");
      }
    } catch (error) {
      setUserAuthState(false);
      history.push("/user-login");
    }
  };

  const checkUserFromWeb = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setUserIdToken(idToken);
      const resp = await getUserInfo(apiCall, userInfoRef.current, idToken);
      console.log(resp);
      if (resp) {
        setUserInfo(resp);
        setUserAuthState(true);
        closeBackdrop();
      } else {
        setUserAuthState(false);
        openBackdrop("Cannot reach the server. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      setUserAuthState(false);
      history.push("/user-login");
    }
  };

  const checkUserAuthState = async () => {
    if (platform.source === "web") {
      await checkUserFromWeb();
    } else {
      if (userIdToken) {
        await checkUserFromApp();
      } else {
        await checkUserFromMLink();
      }
    }
  };

  const checkDeveloperAuthState = async () => {
    setOriginalPath(location.pathname + location.search + location.hash);
    try {
      Amplify.configure(DEV_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const email = session.getIdToken().payload["cognito:username"];
      setDeveloperIdToken(session.getIdToken().getJwtToken());
      setDeveloperEmail(email);
    } catch (error) {
      // developer login is optional
    }
  };

  const checkAuthState = async () => {
    await checkUserAuthState();
    await checkDeveloperAuthState();
  };

  const refreshUserToken = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      setUserAuthState(false);
      history.push("/user-login");
    }
  };

  const refreshDeveloperToken = async () => {
    try {
      Amplify.configure(DEV_AUTH_CONFIG);
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setDeveloperIdToken(idToken);
      return idToken;
    } catch (error) {
      setUserAuthState(false);
      history.push("/developer-login");
    }
  };

  return {
    userAuthState,
    setUserAuthState,
    checkAuthState,
    refreshUserToken,
    refreshDeveloperToken,
  };
};
