import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const NumberInput = ({ valueSpec, onChange }) => {
  const [sValue, setSValue] = useState(null);
  const [tValue, setTValue] = useState("");

  useEffect(() => {
    console.log(valueSpec);
    setSValue(Number(valueSpec.value));
    setTValue(Number(valueSpec.value));
  }, [valueSpec]);

  const step = valueSpec.step || 1; // Default step to 1 if not provided

  const handleSliderChange = (e, v) => {
    setSValue(v);
    setTValue(v);
    onChange(v.toString());
  };

  const handleNumberInputChange = (event) => {
    if (event.target.value === "") {
      setSValue(0);
      setTValue("");
    } else {
      const newValue = Number(event.target.value);
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  const handleIncrement = () => {
    const newValue = sValue + step;
    if (valueSpec.max === null || newValue <= valueSpec.max) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  const handleDecrement = () => {
    const newValue = sValue - step;
    if (valueSpec.min === null || newValue >= valueSpec.min) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString());
    }
  };

  // Determine if the current value is out of range
  const isOutOfRange =
    valueSpec.min !== null &&
    valueSpec.max !== null &&
    (sValue < valueSpec.min || sValue > valueSpec.max);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0,
      }}
    >
      {valueSpec.name && (
        <Typography variant="h6" sx={{ fontSize: "0.875rem", mb: 1 }}>
          {valueSpec.name}
        </Typography>
      )}
      {valueSpec.max !== null && valueSpec.min !== null && (
        <Box width="90%">
          <Slider
            value={sValue}
            aria-label="Slider"
            valueLabelDisplay="off"
            onChange={handleSliderChange}
            min={valueSpec.min}
            max={valueSpec.max}
            step={step}
            sx={{
              height: 4, // Make slider track thinner
              "& .MuiSlider-thumb": {
                width: 12, // Smaller thumb size
                height: 12,
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.16)", // Custom hover effect
                },
                "&.Mui-active": {
                  boxShadow: "0px 0px 0px 14px rgba(0,0,0,0.16)", // Custom active effect
                },
              },
              "& .MuiSlider-track": {
                height: 4, // Track height
              },
              "& .MuiSlider-rail": {
                height: 4, // Rail height
              },
            }}
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        width="90%"
      >
        <Button
          onClick={handleDecrement}
          variant="outlined"
          sx={{
            minWidth: "30px",
            padding: "0 8px",
            fontSize: "0.875rem",
          }}
        >
          -
        </Button>
        <TextField
          type="number"
          value={tValue}
          onChange={handleNumberInputChange}
          sx={{
            width: "100%",
            maxWidth: "120px",
            "& input": {
              textAlign: "center",
              padding: "4px",
              borderColor: isOutOfRange ? "red" : "default",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isOutOfRange ? "red" : "default",
              },
            },
          }}
          inputProps={{
            min: valueSpec.min,
            max: valueSpec.max,
            step: step,
          }}
          error={isOutOfRange}
        />
        <Button
          onClick={handleIncrement}
          variant="outlined"
          sx={{
            minWidth: "30px",
            padding: "0 8px",
            fontSize: "0.875rem",
          }}
        >
          +
        </Button>
      </Box>
    </Box>
  );
};

export default NumberInput;
