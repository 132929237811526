import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../theme";

const AutomationFilter = ({
  sortBy,
  setSortBy,
  setFilterText,
  setShowFilter,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setFilterText(searchInput);
  });

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchValue(searchInput);
    }
  };

  const handleClose = () => {
    setSearchValue("");
    setSearchInput("");
    setShowFilter(false);
  };

  return (
    <Card sx={{ mb: 1.5 }} elevation={0}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: "75%",
            mr: 3,
          }}
        >
          <TextField
            fullWidth
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
            placeholder="fliter text"
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: theme.palette.background.input,
                "& .MuioutlinedInput-input": { padding: "8px 14px" },
                height: "100%",
                boxSizing: "border-box",
              },
            }}
            sx={{
              height: "100%",
            }}
          />
        </Box>

        <FormControl
          variant="outlined"
          sx={{
            width: "35%",
            height: "75%",
          }}
        >
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            sx={{
              width: "100%",
              height: "100%",
            }}
            inputProps={{ style: { padding: "8px" } }}
          >
            <MenuItem value="alphabet">Default sort</MenuItem>
            <MenuItem value="time">Time sort</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Card>
  );
};

export default AutomationFilter;
