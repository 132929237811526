import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { userIdTokenState, userInfoState } from "../global-state";
import { v4 as uuidv4 } from "uuid";
import { useEventEmitter } from "./event-context";
import { SAND_CAT_WS } from "../config";
import { useAuthCheck } from "../auth/auth-check";

// Create the WebSocket context
const WebSocketContext = React.createContext();

// Hook to use the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  const [wsReady, setWsReady] = useState(false);
  const [userInfo] = useRecoilState(userInfoState);
  const [userIdToken] = useRecoilState(userIdTokenState);
  const { emit } = useEventEmitter();
  const messageQueue = useRef({});
  const wsRef = useRef(null);
  const retryDuration = useRef(2000);
  const { checkAuthState, refreshUserToken } = useAuthCheck();
  const userIdTokenRef = useRef(userIdToken);

  useEffect(() => {
    userIdTokenRef.current = userIdToken;
  }, [userIdToken]);

  const connectWebSocket = () => {
    if (
      wsRef.current &&
      (wsRef.current.readyState === WebSocket.OPEN ||
        wsRef.current.readyState === WebSocket.CONNECTING)
    ) {
      return; // Prevent opening multiple WebSocket connections
    }

    console.log("WebSocket connecting... " + userIdTokenRef.current);

    // Check if the current domain is the development environment
    let socketUrl = SAND_CAT_WS;
    const customAddress = localStorage.getItem("localServiceAddress");
    if (customAddress) {
      // Replace "localhost" with the custom address in SAND_CAT_WS
      socketUrl = SAND_CAT_WS.replace("localhost", customAddress);
    }
    socketUrl += `?authorization=Bearer ${userIdTokenRef.current}&scope=${userInfo.activeScope.id}`;

    const websocket = new WebSocket(socketUrl);

    if (retryDuration.current < 300000) {
      retryDuration.current = retryDuration.current * 2;
    }

    websocket.onopen = () => {
      console.log("WebSocket Connection Established");
      setWsReady(true);
      wsRef.current = websocket;
      retryDuration.current = 2000;
    };

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (
        data.type === "SERVER_RESP" &&
        data.sig &&
        messageQueue.current[data.sig]
      ) {
        messageQueue.current[data.sig].resolve(data);
        delete messageQueue.current[data.sig];
      }
      if (data.type === "SERVER_DATA") {
        emit(data.target, data);
      }
    };

    websocket.onclose = async (event) => {
      console.log("WebSocket connection closed:", event);
      setWsReady(false);
      emit("_closed_", null);
      console.log(event.reason);
      if (event.reason === "UNAUTHENTICATED") {
        await refreshUserToken();
      }
      setTimeout(() => connectWebSocket(), retryDuration.current);
    };

    websocket.onerror = (error) => {
      console.error("WebSocket Error:", error);
      websocket.close();
    };

    wsRef.current = websocket;
  };

  useEffect(() => {
    if (!userInfo || !userInfo.activeScope || userInfo.unchanged) {
      console.log("unchanged");
      return;
    }
    if (wsRef.current) {
      wsRef.current.close();
    }
    connectWebSocket();
    return () => {};
  }, [userInfo]);

  // Function to send a message and wait for a response
  const sendAndWaitWs = (target, payload) => {
    const message = {
      target,
      sig: uuidv4(),
      payload,
      type: "CLIENT_REQ",
    };
    return new Promise((resolve, reject) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        messageQueue.current[message.sig] = { resolve, reject };
        wsRef.current.send(JSON.stringify(message));
      } else {
        reject(new Error("Websocket connection is not ready"));
      }
    });
  };

  // Function to send a message without waiting for a response
  const sendAndForgetWs = (target, payload) => {
    const message = {
      target,
      sig: uuidv4(),
      payload,
      type: "CLIENT_DATA",
    };
    return new Promise((resolve, reject) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify(message));
        resolve({});
      } else {
        reject(new Error("Websocket connection is not ready"));
      }
    });
  };

  return (
    <WebSocketContext.Provider
      value={{ ws: wsRef.current, wsReady, sendAndWaitWs, sendAndForgetWs }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
