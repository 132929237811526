import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

const AddWidgetCard = ({ onClick }) => {
  return (
    <Card
      elevation={0}
      sx={{
        mx: 1,
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "2px dashed #ddd",
        transition: "border-color 0.2s",
        "&:hover": {
          borderColor: "#ccc",
        },
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton aria-label="add" color="primary" size="medium">
          <AddIcon fontSize="large" />
        </IconButton>
        <Typography variant="h6" color="textSecondary">
          Add New Widget
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AddWidgetCard;
