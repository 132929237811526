import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

const convert = (val, prevUnit, curUnit) => {
  if (val === null || val === undefined) {
    return null;
  }
  let newVal = val;
  if (prevUnit === "C") {
    if (curUnit === "F") {
      newVal = ((1.0 * newVal) / 5) * 9 + 32;
    }
  } else if (prevUnit === "F") {
    if (curUnit === "C") {
      newVal = ((newVal - 32) / 9) * 5;
    }
  } else if (
    prevUnit === "ms" ||
    prevUnit === "sec" ||
    prevUnit === "min" ||
    prevUnit === "hour" ||
    prevUnit === "day"
  ) {
    if (prevUnit === "sec") {
      newVal = newVal * 1000;
    } else if (prevUnit === "min") {
      newVal = newVal * 1000 * 60;
    } else if (prevUnit === "hour") {
      newVal = newVal * 1000 * 60 * 60;
    } else if (prevUnit === "day") {
      newVal = newVal * 1000 * 60 * 60 * 24;
    }
    if (curUnit === "sec") {
      newVal = newVal / 1000;
    } else if (curUnit === "min") {
      newVal = newVal / 1000 / 60;
    } else if (curUnit === "hour") {
      newVal = newVal / 1000 / 60 / 60;
    } else if (curUnit === "day") {
      newVal = newVal / 1000 / 60 / 60 / 24;
    }
  }
  return newVal;
};

const normalizeByUnit = (spec) => {
  const regex = /^(-?[\d.]+)([a-zA-Z]+)$/;
  const match = regex.exec(spec.value);
  if (match && match.length === 3) {
    // unit value
    const number = parseFloat(match[1]);
    const unit = match[2];
    spec.number = number;
    if (unit === spec.unit) {
      return;
    }
    spec.min = convert(spec.min, spec.unit, unit);
    spec.max = convert(spec.max, spec.unit, unit);
    spec.unit = unit;
  } else {
    // no unit value
    spec.number = Number(spec.value);
  }
};

const NumberUnitInput = ({ valueSpec, onChange }) => {
  const [spec, setSpec] = useState(null);
  const [sValue, setSValue] = useState(0);
  const [tValue, setTValue] = useState(0);
  const [unit, setUnit] = useState(null);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [step, setStep] = useState(null);
  const [outOfRange, setOutOfRange] = useState(false);

  useEffect(() => {
    normalizeByUnit(valueSpec);
    setSpec(valueSpec);
    setSValue(Number(valueSpec.number));
    setTValue(Number(valueSpec.number));
    setUnit(valueSpec.unit);
    setMin(valueSpec.min);
    setMax(valueSpec.max);
    if (valueSpec.steps) {
      for (let i = 0; i < valueSpec.steps.length; i++) {
        if (valueSpec.units[i] === valueSpec.unit) {
          if (valueSpec.steps[i] !== 0) {
            setStep(valueSpec.steps[i]);
          } else {
            setStep(1);
          }
          break;
        }
      }
    } else {
      setStep(1);
    }
    return () => {};
  }, [valueSpec]);

  const handleUnitChange = (event) => {
    const prev = unit;
    const cur = event.target.value;
    if (prev === cur) {
      return;
    }
    setUnit(cur);
    // min, max
    const newMin = convert(min, prev, cur);
    setMin(newMin);
    const newMax = convert(max, prev, cur);
    setMax(newMax);
    setOutOfRange(sValue < newMin || sValue > newMax);
    // step
    let foundStep = false;
    for (let i = 0; i < spec.steps.length; i++) {
      if (spec.units[i] === cur) {
        if (spec.steps[i] !== 0) {
          setStep(spec.steps[i]);
        } else {
          setStep(1);
        }
        foundStep = true;
        break;
      }
    }
    if (!foundStep) {
      setStep(1);
    }
    console.log(newMax);
    console.log(newMin);
    console.log(cur);
    onChange(sValue.toString(), cur, newMax, newMin);
  };

  const handleSliderChange = (e, v) => {
    console.log(v);
    setSValue(v);
    setTValue(v);
    onChange(v.toString(), unit, max, min);
  };

  const handleNumberTextChange = (event) => {
    if (event.target.value === "") {
      setSValue(0);
      setTValue("");
    } else {
      const newValue = Number(event.target.value);
      setSValue(newValue);
      setTValue(event.target.value);
      onChange(newValue.toString(), unit, max, min);
    }
    const newValue = Number(event.target.value);
    setOutOfRange(newValue < min || newValue > max);
  };

  const handleIncrement = () => {
    const newValue = sValue + step;
    if (newValue <= max) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString(), unit, max, min);
    }
  };

  const handleDecrement = () => {
    const newValue = sValue - step;
    if (newValue >= min) {
      setSValue(newValue);
      setTValue(newValue);
      onChange(newValue.toString(), unit, max, min);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0,
      }}
    >
      {valueSpec.name && <Typography variant="h6">{valueSpec.name}</Typography>}
      <Box width="90%">
        <Slider
          value={sValue}
          aria-label="Slider"
          valueLabelDisplay="off"
          onChange={handleSliderChange}
          min={min}
          max={max}
          step={step}
          sx={{
            height: 4, // Make slider track thinner
            "& .MuiSlider-thumb": {
              width: 12, // Smaller thumb size
              height: 12,
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.16)", // Custom hover effect
              },
              "&.Mui-active": {
                boxShadow: "0px 0px 0px 14px rgba(0,0,0,0.16)", // Custom active effect
              },
            },
            "& .MuiSlider-track": {
              height: 4, // Track height
            },
            "& .MuiSlider-rail": {
              height: 4, // Rail height
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        width="90%"
      >
        <Button
          onClick={handleDecrement}
          variant="outlined"
          sx={{
            minWidth: "30px",
            padding: "0 8px",
            fontSize: "0.875rem",
          }}
        >
          -
        </Button>
        <TextField
          type="number"
          value={tValue}
          onChange={handleNumberTextChange}
          sx={{
            width: "100%",
            maxWidth: "120px",
            "& input": {
              textAlign: "center",
              padding: "4px",
              borderColor: outOfRange ? "red" : "default",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: outOfRange ? "red" : "default",
              },
            },
          }}
          inputProps={{
            min: min,
            max: max,
            step: step,
          }}
          error={outOfRange}
        />
        <Button
          onClick={handleIncrement}
          variant="outlined"
          sx={{
            minWidth: "30px",
            padding: "0 8px",
            fontSize: "0.875rem",
          }}
        >
          +
        </Button>
        <FormControl
          fullWidth
          sx={{
            flexGrow: 1,
            minWidth: 0,
            "& .MuiInputLabel-root": {
              fontSize: "0.75rem",
              transform: "translate(8px, -6px) scale(0.75)", // Adjusted label positioning
              "&.MuiInputLabel-shrink": {
                transform: "translate(8px, -9px) scale(0.75)",
              },
            },
            "& .MuiSelect-select": {
              padding: "4px 8px",
              minHeight: "26px", // Consistent with other inputs
              lineHeight: "1.2",
            },
            "& .MuiOutlinedInput-root": {
              minHeight: "26px", // Consistent with other inputs
              "& fieldset": {
                borderRadius: "4px",
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              fontSize: "0.75rem", // Match label size
            },
            // Adjust menu items to be more compact
            "& .MuiMenuItem-root": {
              minHeight: "24px",
              padding: "4px 8px",
              fontSize: "0.875rem",
            },
          }}
        >
          <InputLabel id="unit-select-label">Unit</InputLabel>
          <Select
            labelId="unit-select-label"
            id="unit-select"
            value={unit}
            label="Unit"
            onChange={handleUnitChange}
          >
            {valueSpec.units.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default NumberUnitInput;
