export const deletePremade = async (apiCall, premadeId) => {
  const request = { id: premadeId };
  const response = await apiCall("/premade/delete", "POST", request, "dev");
  return response && !response.error;
};

export const getPremade = async (apiCall, premadeId) => {
  return await apiCall(
    `/premade/script?id=${premadeId}`,
    "GET",
    null,
    "user,dev"
  );
};

export const savePremade = async (apiCall, id, script, descriptions) => {
  const request = { script, descriptions };
  if (id) {
    request.id = id;
  }
  return await apiCall("/premade/save", "POST", request, "dev");
};

export const publishPremade = async (apiCall, id) => {
  const request = { id };
  return await apiCall("/premade/publish", "POST", request, "dev");
};

export const archivePremade = async (apiCall, id, version) => {
  const request = { id, version };
  const response = await apiCall("/premade/archive", "POST", request, "dev");
  return response && !response.error ? response.archived : false;
};

export const buildPremade = async (apiCall, script) => {
  const request = { script };
  return await apiCall("/premade/build", "POST", request, "dev");
};

export const listPremades = async (apiCall) => {
  return await apiCall("/premade/list", "GET", null, "user,dev");
};

export const searchPremades = async (apiCall, searchText) => {
  const request = { searchText };
  return await apiCall("/premade/search", "POST", request, "user,dev");
};

export const listPremadeExamples = async (apiCall) => {
  return await apiCall("/premade/examples", "GET", null, "dev");
};

export const getPremadeExample = async (apiCall, name) => {
  return await apiCall(`/premade/example?name=${name}`, "GET", null, "dev");
};

export const formatPremade = async (apiCall, script) => {
  const request = { script, untrusted: true };
  return await apiCall("/premade/format", "POST", request, "dev");
};

export const aiDescribePremade = async (apiCall, script) => {
  const request = { task: "describe", script, untrusted: true };
  return await apiCall("/premade/ai-edit", "POST", request, "dev");
};
