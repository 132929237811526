import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useRecoilState } from "recoil";
import {
  developerIdTokenState,
  userIdTokenState,
  userInfoState,
} from "../global-state";
import useApiCall from "./api-call";

const OAuthFlow = ({
  oAuthInfo,
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userIdToken, setUserIdToken] = useRecoilState(userIdTokenState);
  const [oauthWindow, setOauthWindow] = useState(null);
  const { apiCall } = useApiCall();
  const [processedEvents, setProcessedEvents] = useState(new Set());

  useEffect(() => {
    const handleMessage = (event) => {
      const messageData = event.data;
      if (messageData.event === "oauthCallback") {
        const eventId = JSON.stringify(messageData.data);
        if (!processedEvents.has(eventId)) {
          processedEvents.add(eventId);
          handleOAuthCode(messageData.data);
        }
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );

  const initOAuthFlow = async () => {
    if (oAuthInfo) {
      const authorizationUrl = `${oAuthInfo.authUrl}?client_id=${
        oAuthInfo.clientId
      }&redirect_uri=${
        oAuthInfo.redirectUri
      }&response_type=code&access_type=offline&scope=${oAuthInfo.scopes.join(
        " "
      )}&state=${oAuthInfo.integration}`;
      console.log(authorizationUrl);
      const windowRef = window.open(
        authorizationUrl,
        "OAuth Popup",
        "width=600,height=400"
      );
      setOauthWindow(windowRef);
    }
  };

  const handleOAuthCode = async (data) => {
    console.log(data);
    const req = {
      oauthCode: data.code,
      oauthScope: data.scope,
      oauthState: data.state,
      integration: oAuthInfo.integration,
      integrationConnect: oAuthInfo.integrationConnect,
      scopeId: userInfo.activeScope.id,
    };
    const response = await apiCall(
      "/integration/oauth/code/callback",
      "POST",
      req,
      "user,dev"
    );
    if (response) {
      onSuccess();
    } else {
      onFailure();
    }
  };

  return (
    <Button variant="contained" onClick={initOAuthFlow}>
      Connect
    </Button>
  );
};

export default OAuthFlow;
