import Snackbar from "@mui/material/Snackbar";
import React, { createContext, useContext, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastContext = createContext(null);

export function useToast() {
  return useContext(ToastContext);
}

export const ToastProvider = ({ children }) => {
  const [notification, setNotification] = useState("");
  const [notificationLevel, setNotificationLevel] = useState("success");
  const [notificationOpen, setNotificationOpen] = useState(false);
  const theme = useTheme();

  const errorToast = (msg) => {
    setNotification(msg);
    setNotificationLevel("error");
    setNotificationOpen(true);
  };

  const warningToast = (msg) => {
    setNotification(msg);
    setNotificationLevel("warning");
    setNotificationOpen(true);
  };

  const successToast = (msg) => {
    setNotification(msg);
    setNotificationLevel("success");
    setNotificationOpen(true);
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationOpen(false);
  };

  const getBackgroundColor = (level) => {
    switch (level) {
      case "success":
        return theme.palette.background.l1;
      case "error":
        return theme.palette.background.l4;
      case "warning":
        return theme.palette.background.l3;
      default:
        return theme.palette.background.l0;
    }
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleNotificationClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <ToastContext.Provider value={{ successToast, warningToast, errorToast }}>
      {children}
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={notificationLevel}
          sx={{
            width: "100%",
            backgroundColor: getBackgroundColor(notificationLevel),
            color: theme.palette.text.black,
          }}
          action={action}
          onClose={handleNotificationClose}
        >
          {notification}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
