import React, { createContext, useCallback, useContext, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";

const BackdropContext = createContext(null);

export function useBackdrop() {
  return useContext(BackdropContext);
}

export const BackdropProvider = ({ children }) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");

  const openBackdrop = useCallback((message) => {
    setBackdropMessage(message);
    setBackdropOpen(true);
  }, []);

  const closeBackdrop = useCallback(() => {
    setBackdropOpen(false);
    setBackdropMessage("");
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <BackdropContext.Provider value={{ openBackdrop, closeBackdrop }}>
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {backdropMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.3)",
              },
            }}
          >
            Refresh Page
          </Button>
        </Box>
      </Backdrop>
    </BackdropContext.Provider>
  );
};
