import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const TextChatCard = ({ message, data }) => {
  const theme = useTheme();

  const getColor = () => {
    if (data.level === "WARNING") {
      return theme.palette.background.c2;
    } else if (data.level === "ERROR") {
      return theme.palette.background.c3;
    }
    return theme.palette.background.c0;
  };

  return (
    <Box
      sx={{
        maxWidth: "80%",
        backgroundColor: getColor(),
        borderRadius: "12px",
        padding: "8px",
      }}
    >
      <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {message}
      </Typography>
      {data.level === "DEBUG" && (
        <Typography
          variant="body2"
          sx={{ marginTop: "4px", color: theme.palette.text.secondary }}
        >
          Elapsed Time: {data.elapsed} ms
        </Typography>
      )}
    </Box>
  );
};

export default TextChatCard;
