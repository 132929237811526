export const getUserInfo = async (apiCall, userInfo, userToken = null) => {
  let response;
  if (userToken) {
    response = await apiCall(
      "/auth/user/info",
      "GET",
      null,
      "user",
      0,
      userToken,
      null
    );
  } else {
    response = await apiCall("/auth/user/info", "GET", null);
  }
  if (response) {
    response.unchanged = compareUserInfo(userInfo, response);
  }
  return response;
};

export const getLinkedUser = async (
  apiCall,
  linkOrgId,
  linkOrgUserId,
  userToken
) => {
  const response = await apiCall(
    `/auth/user/linked?org=${linkOrgId}&user=${linkOrgUserId}`,
    "GET",
    null,
    "user",
    0,
    userToken,
    null
  );
  if (response) {
    if (response.linked) {
      return response;
    }
    response.userInfo.unchanged = true;
  }
  return response;
};

const compareUserInfo = (o, n) => {
  return (
    n.id === o.id &&
    n.name === o.name &&
    n.activeScope.id === o.activeScope.id &&
    n.activeScope.name === o.activeScope.name &&
    n.activeScope.untrusted === o.activeScope.untrusted &&
    n.token === o.token
  );
};

export const saveUserSetting = async (apiCall, setting) => {
  const response = await apiCall("/auth/user/setting/save", "POST", setting);
  return response && response.success;
};
