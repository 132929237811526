import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

const DeleteCardDialog = ({ open, card, onClose, onDelete }) => {
  const [cardId, setCardId] = useState("");

  const theme = useTheme();

  useEffect(() => {
    if (card) {
      setCardId(card.id);
    }
  }, [card]);

  const handleDelete = () => {
    onDelete(cardId);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Card</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" gutterBottom>
          The card is no longer used. Do you want to delete?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCardDialog;
