import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";

const TimeInput = ({ valueSpec, onChange }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(dayjs(valueSpec.value, "HH:mm:ss"));
    return () => {};
  }, [valueSpec]);

  const handleValueChange = (data) => {
    const h = data?.$H ?? 0;
    const m = data?.$m ?? 0;
    const s = data?.$s ?? 0;
    const time = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
      s < 10 ? "0" + s : s
    }`;
    setValue(data);
    onChange(time);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Typography
          variant="h6"
          noWrap
          sx={{
            flexShrink: 0,
            mb: 1,
          }}
        >
          {valueSpec.name}
        </Typography>
      )}
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            value={value}
            onAccept={handleValueChange}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                sx: {
                  flex: 1,
                  "& .MuiInputBase-root": {
                    height: "100%",
                    minHeight: "26px",
                  },
                  "& .MuiInputBase-input": {
                    height: "100%",
                    padding: "0 8px",
                    display: "flex",
                    alignItems: "center",
                  },
                },
              },
            }}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              "& .MuiTextField-root": {
                flex: 1,
                display: "flex",
              },
              "& .MuiPickersLayout-root": {
                minHeight: "auto",
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default TimeInput;
