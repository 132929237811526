const scanGlobalVariableDef = (code) => {
  const lines = code.split("\n");
  const varDefs = {};
  const errs = [];
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith("inputVars:")) {
      let curVarDef = null;
      while (true) {
        i++;
        if (i >= lines.length) {
          if (curVarDef && curVarDef.name) {
            varDefs[curVarDef.name] = curVarDef;
          }
          break;
        }
        const lineInfo = parseLine(i, lines[i]);
        if (lineInfo.empty) {
          continue;
        }
        const spaceCount = lineInfo.spaceCount;
        const arraySpaceCount = lineInfo.arraySpaceCount;
        const isArray = lineInfo.arrayStart;
        if (spaceCount === 0) {
          if (curVarDef && curVarDef.name) {
            varDefs[curVarDef.name] = curVarDef;
          }
          break;
        }
        if (isArray) {
          if (curVarDef && curVarDef.name) {
            varDefs[curVarDef.name] = curVarDef;
          }
          curVarDef = {};
        }
        if (lineInfo.key === "name") {
          if (lineInfo.value) {
            if (isValidVar(lineInfo.value)) {
              curVarDef.name = lineInfo.value;
            } else {
              const err = {
                start: lineInfo.valuePos.start,
                end: lineInfo.valuePos.end,
                description: "Bad variable name.",
              };
              errs.push(err);
            }
          }
        } else if (lineInfo.key === "repeated") {
          curVarDef.repeated = lineInfo.value === "true";
        } else if (lineInfo.key === "types") {
          if (lineInfo.value) {
            curVarDef.types = [lineInfo.value];
          } else {
            curVarDef.types = [];
            while (true) {
              i++;
              if (i >= lines.length) {
                break;
              }
              const subLineInfo = parseLine(i, lines[i], true);
              if (subLineInfo.spaceCount > lineInfo.spaceCount) {
                curVarDef.types.push(subLineInfo.value);
              } else {
                i--;
                break;
              }
            }
          }
        } else if (lineInfo.key === "enums") {
          if (lineInfo.value) {
            curVarDef.enums = [lineInfo.value];
          } else {
            curVarDef.enums = [];
            while (true) {
              i++;
              if (i >= lines.length) {
                break;
              }
              const subLineInfo = parseLine(i, lines[i], true);
              if (subLineInfo.spaceCount > lineInfo.spaceCount) {
                curVarDef.enums.push(subLineInfo.value);
              } else {
                i--;
                break;
              }
            }
          }
        }
        // TODO: min/max/step
      }
      break;
    }
  }
  return { varDefs, errs };
};

// const scanGlobalVariableValue = (code, varDefs) => {
//     const lines = code.split('\n');
//     const varValues = {};
//     const errs = [];
//     for (let i = 0; i < lines.length; i++) {
//         if (lines[i].startsWith('$')) {
//             const lineInfo = parseLine(i, lines[i]);
//             if (!lineInfo.key) {
//                 continue;
//             }
//             if (isValidVar(lineInfo.key)) {
//                 const err = {
//                     start: lineInfo.keyPos.start, end: lineInfo.keyPos.end,
//                     description: 'Bad variable name.'
//                 };
//                 errs.push(err);
//                 continue;
//             }
//             if (!varDefs[lineInfo.key]) {
//                 const err = {
//                     start: lineInfo.keyPos.start, end: lineInfo.keyPos.end,
//                     description: 'Variable is not defined in \'inputVars\'.'
//                 };
//                 errs.push(err);
//                 continue;
//             }
//             if (lineInfo.value) {
//
//             }
//             while (true) {
//                 i++;
//                 if (i >= lines.length) {
//                     if (curVarDef && curVarDef.name) {
//                         varDefs[curVarDef.name] = curVarDef;
//                     }
//                     break;
//                 }
//                 const lineInfo = parseLine(i, lines[i]);
//                 if (lineInfo.empty) {
//                     continue;
//                 }
//                 const spaceCount = lineInfo.spaceCount;
//                 const arraySpaceCount = lineInfo.arraySpaceCount;
//                 const isArray = lineInfo.arrayStart;
//                 if (spaceCount === 0) {
//                     if (curVarDef && curVarDef.name) {
//                         varDefs[curVarDef.name] = curVarDef;
//                     }
//                     break;
//                 }
//                 if (isArray) {
//                     if (curVarDef && curVarDef.name) {
//                         varDefs[curVarDef.name] = curVarDef;
//                     }
//                     curVarDef = {};
//                 }
//                 if (lineInfo.key === 'name') {
//                     curVarDef.name = lineInfo.value;
//                 } else if (lineInfo.key === 'repeated') {
//                     curVarDef.repeated = lineInfo.value === 'true';
//                 } else if (lineInfo.key === 'types') {
//                     if (lineInfo.value) {
//                         curVarDef.types = [lineInfo.value];
//                     } else {
//                         curVarDef.types = [];
//                         while (true) {
//                             i++;
//                             if (i >= lines.length) {
//                                 break;
//                             }
//                             const subLineInfo = parseLine(i, lines[i], true);
//                             if (subLineInfo.spaceCount > lineInfo.spaceCount) {
//                                 curVarDef.types.push(subLineInfo.value);
//                             } else {
//                                 i--;
//                                 break;
//                             }
//                         }
//                     }
//                 } else if (lineInfo.key === 'enums') {
//                     if (lineInfo.value) {
//                         curVarDef.enums = [lineInfo.value];
//                     } else {
//                         curVarDef.enums = [];
//                         while (true) {
//                             i++;
//                             if (i >= lines.length) {
//                                 break;
//                             }
//                             const subLineInfo = parseLine(i, lines[i], true);
//                             if (subLineInfo.spaceCount > lineInfo.spaceCount) {
//                                 curVarDef.enums.push(subLineInfo.value);
//                             } else {
//                                 i--;
//                                 break;
//                             }
//                         }
//                     }
//                 }
//                 // TODO: min/max/step
//             }
//             break;
//         }
//     }
//     return varDefs;
// }

export const getContext = async (code, cursor, specData, typing) => {
  const context = { errors: [], integration: null, suggestion: {}, detail: {} };

  // Scan global variables
  const globalVarDefRes = scanGlobalVariableDef(code);
  const globalVarDefs = globalVarDefRes.varDefs;
  context.errors = context.errors.concat(globalVarDefRes.errs);
  console.log(globalVarDefs);
  // const globalVarValueRes = scanGlobalVariableValue(code, globalVarDefs);
  // const globalVarValues = globalVarValueRes.varValues;
  // context.errors = context.errors.concat(globalVarValueRes.errs);

  const row = cursor.row;
  const col = cursor.col;
  const lines = code.split("\n");

  let nextSpec = await specData.getSpec("Script");
  const stack = [
    {
      spaceCount: -1,
      arraySpaceCount: -1,
      isArray: false,
      value: false,
      cursor: false,
      content: [],
    },
  ];

  let keyPos = null;
  let nextMultiLineValue = false;
  let nextIsArray = false;
  let valueTypes = [];
  let rowCur = null;
  let rowCurValue = false;
  let rowCurKey = null;
  let rowValueTypes = [];
  let nextKey = null;
  let nextArrayValue = 0;

  for (let i = 0; i < lines.length; i++) {
    const lineInfo = parseLine(i, lines[i]);
    let cur1 = stack[stack.length - 1];
    if (cur1 && cur1.multiLineValue) {
      console.log(lineInfo);
    }
    if (lineInfo.empty) {
      continue;
    }

    const spaceCount = lineInfo.spaceCount;
    const arraySpaceCount = lineInfo.arraySpaceCount;
    const isArray = lineInfo.arrayStart;
    if (isArray) {
      let cur = stack[stack.length - 1];
      while (arraySpaceCount < cur.arraySpaceCount) {
        const poped = stack.pop();
        populateCursorContent(poped, stack, context);
        cur = stack[stack.length - 1];
        if (cur.arraySpaceCount < arraySpaceCount) {
          const err = {
            start: { row: i, col: 0 },
            end: { row: i, col: lines[i].length },
            description: "Bad indentation.",
          };
          context.errors.push(err);
          return context;
        }
      }
    } else {
      let cur = stack[stack.length - 1];
      while (spaceCount < cur.spaceCount) {
        const poped = stack.pop();
        populateCursorContent(poped, stack, context);
        cur = stack[stack.length - 1];
        if (cur.spaceCount < spaceCount) {
          const err = {
            start: { row: i, col: 0 },
            end: { row: i, col: lines[i].length },
            description: "Bad indentation.",
          };
          context.errors.push(err);
          return context;
        }
      }
    }

    let modifyStack = true;
    let arrayValueNext = false;
    let cur = stack[stack.length - 1];
    if (cur.multiLineValue) {
      modifyStack = false;
    } else if (cur.arrayValue) {
      if (
        isArray &&
        spaceCount === cur.spaceCount &&
        arraySpaceCount === cur.arraySpaceCount
      ) {
        modifyStack = false;
      } else {
        arrayValueNext = true;
      }
    }
    if (modifyStack) {
      if (spaceCount > cur.spaceCount || arrayValueNext) {
        let spec = nextSpec;
        if (nextIsArray) {
          if (!isArray) {
            const err = {
              start: { row: i, col: arraySpaceCount },
              end: { row: i, col: lines[i].length },
              description: "Use an array.",
            };
            context.errors.push(err);
            spec = null;
          }
        } else {
          if (isArray) {
            const err = {
              start: { row: i, col: arraySpaceCount },
              end: { row: i, col: lines[i].length },
              description: "Use key/value pairs.",
            };
            context.errors.push(err);
            spec = null;
          }
        }
        cur = {
          spaceCount,
          arraySpaceCount,
          isArray,
          spec,
          multiLineValue: nextMultiLineValue,
          arrayValue: nextArrayValue === 2 ? isArray : nextArrayValue === 1,
          keyPos,
          key: nextKey,
          cursor: false,
          content: [],
          value: "",
          values: [],
        };
        stack.push(cur);
        if (cur.spec) {
          const namespaceKey = cur.spec.namespaceKey;
          if (namespaceKey) {
            const newSpec = await findType(
              i,
              spaceCount,
              namespaceKey,
              cur,
              lines,
              specData,
              context
            );
            cur.parentSpec = cur.spec;
            if (newSpec) {
              cur.spec = newSpec;
            }
          } else {
            // pass
          }
        }
      } else {
        if (isArray && cur.parentSpec) {
          const poped = stack.pop();
          populateCursorContent(poped, stack, context);
          cur = {
            spaceCount,
            arraySpaceCount,
            isArray,
            spec: null,
            multiLineValue: false,
            arrayValue: false,
            valueTypes: [],
            keyPos,
            cursor: false,
            content: [],
            value: "",
            values: [],
          };
          stack.push(cur);
          cur.parentSpec = poped.parentSpec;
          const newSpec = await findType(
            i,
            spaceCount,
            cur.parentSpec.namespaceKey,
            cur,
            lines,
            specData,
            context
          );
          if (newSpec) {
            cur.spec = newSpec;
          } else {
            cur.spec = cur.parentSpec;
          }
        }
      }
    }

    const spec = cur.spec;
    if (spec) {
      const key = lineInfo.key;
      nextKey = key;
      keyPos = lineInfo.keyPos;
      if (!key) {
        const err = {
          start: { row: i, col: 0 },
          end: { row: i, col: lines[i].length },
          description: "Enter a key.",
        };
        context.errors.push(err);
        nextSpec = null;
        nextMultiLineValue = false;
        valueTypes = [];
      } else if (!(key in spec.fields)) {
        if (key.startsWith("$")) {
          nextSpec = null;
          nextMultiLineValue = lineInfo.value === "|" || lineInfo.value === ">";
          nextArrayValue = 0;
          valueTypes = ["Dynamic"];
        } else {
          const err = {
            start: keyPos.start,
            end: keyPos.end,
            description: "Unknown key.",
          };
          context.errors.push(err);
          nextSpec = null;
          nextMultiLineValue = false;
          nextArrayValue = 2;
          valueTypes = [];
        }
      } else {
        const value = lineInfo.value;
        nextSpec = spec.fields[key].spec
          ? await specData.getSpec(spec.fields[key].spec)
          : null;
        nextIsArray = spec.fields[key].repeated || false;
        valueTypes = spec.fields[key].types || [];
        nextMultiLineValue = value === "|" || value === ">";
        if (nextIsArray && valueTypes.length > 0) {
          nextArrayValue = 1;
        } else {
          nextArrayValue = 0;
        }
      }
      if (lineInfo.key && !nextMultiLineValue) {
        if (nextArrayValue === 0) {
          cur.content.push({
            key: lineInfo.key,
            value: lineInfo.value || "",
            values: [],
          });
        } else if (nextArrayValue === 2 && lineInfo.value) {
          cur.content.push({
            key: lineInfo.key,
            value: lineInfo.value,
            values: [],
          });
        }
      }
    } else if (cur.arrayValue) {
      nextMultiLineValue = lineInfo.value === "|" || lineInfo.value === ">";
      nextArrayValue = 0;
      nextIsArray = !nextMultiLineValue;
      if (!nextMultiLineValue) {
        cur.values.push(lineInfo.value || "");
      }
    } else if (cur.multiLineValue) {
      if (cur.value === "") {
        cur.value = lineInfo.value || "";
      } else {
        cur.value = cur.value + "\n" + (lineInfo.value || "");
      }
    }

    // provide cursor related info
    if (row === i) {
      // current integration
      for (let k = stack.length - 1; k >= 0; k--) {
        if (stack[k].spec && stack[k].spec.namespace) {
          const specName = stack[k].parentSpec.prefix + stack[k].spec.namespace;
          context.integration = await specData.getSpec(specName).integration;
          break;
        }
      }
      rowCur = stack[stack.length - 1];
      console.log(rowCur);
      rowCur.cursor = true;
      rowValueTypes = [...valueTypes];
      rowCurValue = rowCur.arrayValue;
      rowCurKey = rowCur.key;
    }
  }

  while (stack.length > 0) {
    const poped = stack.pop();
    if (poped.cursor) {
      populateCursorContent(poped, stack, context);
      rowCur = poped;
    }
  }

  if (rowCur) {
    // Process cursor
    const curLineInfo = parseLine(row, lines[row], rowCurValue, col);
    if (
      curLineInfo.suggestKey !== null &&
      curLineInfo.suggestKey !== undefined
    ) {
      const existingKeys = (context.detail.content || []).map((x) => x.key);
      if (rowCur.spec) {
        context.suggestion.options = Object.keys(rowCur.spec.fields).filter(
          (x) =>
            x.startsWith(curLineInfo.suggestKey) &&
            x !== curLineInfo.suggestKey &&
            existingKeys.indexOf(x) === -1
        );
        context.suggestion.row = row;
        context.suggestion.start = curLineInfo.suggestStart;
        context.suggestion.end = curLineInfo.suggestEnd;
        if (
          curLineInfo.suggestKey in rowCur.spec.fields &&
          existingKeys.indexOf(curLineInfo.suggestKey) === -1
        ) {
          const childSpecName = rowCur.spec.fields[curLineInfo.suggestKey].spec;
          let childRepeated =
            rowCur.spec.fields[curLineInfo.suggestKey].repeated || false;
          const childSpace = curLineInfo.spaceCount + 2;
          const fill = await suggestFill(
            childSpace,
            childSpecName,
            childRepeated,
            [],
            specData
          );
          if (fill !== "") {
            context.suggestion.fill =
              ":\n" + fill.substring(0, fill.length - 1);
          } else {
            context.suggestion.fill = ":";
          }
        }
      }
    } else if (
      curLineInfo.suggestValue !== null &&
      curLineInfo.suggestValue !== undefined
    ) {
      if (rowValueTypes.indexOf("type") >= 0) {
        const types = specData.getIntegrationTypes();
        const prefix = rowCur.parentSpec.prefix;
        context.suggestion.options = types
          .filter((x) => {
            return (
              x.startsWith(prefix + curLineInfo.suggestValue) &&
              x !== prefix + curLineInfo.suggestValue
            );
          })
          .map((x) => x.substring(2));
        if (types.includes(prefix + curLineInfo.suggestValue)) {
          const childSpecName = prefix + curLineInfo.suggestValue;
          const childSpace = curLineInfo.spaceCount;
          const skipKey = rowCur.parentSpec.namespaceKey;
          const fill = await suggestFill(
            childSpace,
            childSpecName,
            false,
            [skipKey],
            specData
          );
          if (fill !== "") {
            context.suggestion.fill = "\n" + fill.substring(0, fill.length - 1);
          }
        }
      } else {
        const fieldSpec = await specData.getFieldSpec(
          context.detail.name,
          context.detail.integration,
          context.detail.content
        );
        console.log(fieldSpec);
        let options = [];
        const arrayKeyValue = `${rowCurKey}@${curLineInfo.value}`;
        const field =
          fieldSpec[curLineInfo.key] ||
          fieldSpec[arrayKeyValue] ||
          rowCur.spec.fields[curLineInfo.key] ||
          {};
        console.log(field);
        console.log(arrayKeyValue);
        if (field.value) {
          options = field.value.enums || [];
        }
        options = options.filter(
          (x) =>
            x.startsWith(curLineInfo.suggestValue) &&
            x !== curLineInfo.suggestValue
        );
        context.suggestion.options = options;
      }
      context.suggestion.row = row;
      context.suggestion.start = curLineInfo.suggestStart;
      context.suggestion.end = curLineInfo.suggestEnd;
    }
  }

  return context;
};

const populateCursorContent = (poped, stack, context) => {
  if (poped.multiLineValue) {
    const peek = stack[stack.length - 1];
    peek.cursor = true;
    if (peek.arrayValue) {
      peek.values.push(poped.value);
    } else {
      peek.content.push({ key: poped.key, value: poped.value });
    }
  } else if (poped.arrayValue) {
    const peek = stack[stack.length - 1];
    peek.cursor = true;
    peek.content.push({ key: poped.key, values: poped.values });
  }
  if (poped.cursor) {
    if (poped.spec) {
      context.detail.name = poped.spec.name;
      context.detail.integration = poped.spec.integration;
      context.detail.content = poped.content;
    }
  }
};

const suggestFill = async (space, specName, repeated, skipKey, specData) => {
  let fill = "";
  console.log(specName);
  console.log(repeated);
  if (specName) {
    let spaceString = "";
    for (let i = 0; i < space; i++) {
      spaceString += " ";
    }
    const spec = await specData.getSpec(specName);
    const respKeys = Object.entries(spec.fields)
      .filter(([key, value]) => value.source === "RESP")
      .map(([key, value]) => key);
    for (const ckey in spec.fields) {
      if (skipKey.includes(ckey)) {
        continue;
      }
      if (spec.fields[ckey].optional) {
        if (
          respKeys.length === 1 &&
          respKeys.indexOf(ckey) !== -1 &&
          spec.prefix === "c/"
        ) {
          // pass
        } else {
          continue;
        }
      }
      fill = `${fill}${spaceString}${repeated ? "- " : ""}${ckey}:\n`;
      if (repeated) {
        repeated = false;
        spaceString += "  ";
        space += 2;
      }
      const childSpecName = spec.fields[ckey].spec;
      const childRepeated = spec.fields[ckey].repeated;
      if (childSpecName) {
        const childFill = await suggestFill(
          space + 2,
          childSpecName,
          childRepeated,
          [],
          specData
        );
        fill += childFill;
      } else if (childRepeated) {
        const childFill = spaceString + "  - \n";
        fill += childFill;
      }
    }
  } else if (repeated) {
    let spaceString = "";
    for (let i = 0; i < space; i++) {
      spaceString += " ";
    }
    fill += `${spaceString}- \n`;
  }
  return fill;
};

const findType = async (
  i,
  spaceCount,
  namespaceKey,
  curCtx,
  lines,
  specData,
  context
) => {
  let ll = parseLine(i, lines[i]);
  let j = i;
  let namespace = null;
  while (true) {
    if (spaceCount === ll.spaceCount) {
      if (ll.key === namespaceKey) {
        namespace = ll.value;
        break;
      }
      if (ll.arrayStart && j !== i) {
        break;
      }
    } else if (spaceCount > ll.spaceCount) {
      break;
    }
    j++;
    if (j >= lines.length) {
      break;
    }
    ll = parseLine(j, lines[j]);
  }
  if (namespace) {
    const newSpec = await specData.getSpec(
      (curCtx.parentSpec || curCtx.spec).prefix + namespace
    );
    if (!newSpec) {
      const err = {
        start: ll.keyPos.start,
        end: ll.keyPos.end,
        description: `Integration 'type: ${namespace}' is unknown.`,
      };
      context.errors.push(err);
    }
    return newSpec || null;
  } else {
    const err = {
      start: curCtx.keyPos.start,
      end: curCtx.keyPos.end,
      description: `Please enter '${namespaceKey}'.`,
    };
    context.errors.push(err);
    return null;
  }
};

/**
 *
 * @param {number} row row number
 * @param {string} rawLine line content
 * @param {boolean} valueCtx if in a literal value context
 * @param {?number} col col number
 * @returns {{empty: boolean}|{arraySpaceCount: number, arrayStart: boolean, keyPos: {start: {col: number, row}, end: {col: number, row}}, suggestKey: string, value: string, spaceCount: number, key: string}|{value: string, spaceCount: number}}
 */
const parseLine = (row, rawLine, valueCtx, col) => {
  let line =
    col === undefined || col === null ? rawLine : rawLine.substring(0, col);

  let spaceCount = 0;
  for (let i = 0; i < line.length && line[i] === " "; i++) {
    spaceCount++;
  }

  if (valueCtx) {
    const cmtIdx = line.indexOf(" #");
    if (cmtIdx >= 0) {
      line = line.substring(0, cmtIdx);
    }
    if (line.substring(spaceCount).startsWith("- ")) {
      spaceCount++;
      for (let i = spaceCount; i < line.length && line[i] === " "; i++) {
        spaceCount++;
      }
    }
    const value = line.substring(spaceCount);
    return { spaceCount, value, suggestValue: value };
  }

  if (line[spaceCount] === "#") {
    return { empty: true };
  }

  const cmtIdx = line.indexOf(" #");
  if (cmtIdx >= 0) {
    line = line.substring(0, cmtIdx);
  }

  let arrayStart = false;
  const arraySpaceCount = spaceCount;
  if (line.substring(spaceCount).startsWith("- ")) {
    arrayStart = true;
    spaceCount++;
    for (let i = spaceCount; i < line.length && line[i] === " "; i++) {
      spaceCount++;
    }
  }
  let keyIdx = line.indexOf(": ");
  if (keyIdx === -1 && line.endsWith(":")) {
    keyIdx = line.length - 1;
  }
  let valIdx = keyIdx >= 0 ? keyIdx + 1 : spaceCount;
  for (let i = valIdx; i < line.length && line[i] === " "; i++) {
    valIdx++;
  }

  const key = keyIdx >= 0 ? line.substring(spaceCount, keyIdx) : null;
  const value = valIdx >= line.length ? null : line.substring(valIdx).trimEnd();
  const keyPos =
    keyIdx >= 0
      ? { start: { row: row, col: spaceCount }, end: { row: row, col: keyIdx } }
      : null;
  const valuePos =
    valIdx >= line.length
      ? null
      : {
          start: { row: row, col: valIdx },
          end: { row: row, col: line.length },
        };

  const suggestKey = keyIdx >= 0 ? null : line.substring(spaceCount);
  const suggestValue =
    keyIdx >= 0 ? (keyIdx === line.length - 1 ? null : value || "") : null;
  const suggestStart = keyIdx >= 0 ? valIdx : spaceCount;
  const suggestEnd =
    keyIdx >= 0 ? valIdx + (value ? value.length : 0) : line.length;

  return {
    spaceCount,
    arraySpaceCount,
    arrayStart,
    key,
    value,
    keyPos,
    valuePos,
    suggestKey,
    suggestValue,
    suggestStart,
    suggestEnd,
  };
};

const isValidVar = (name) => {
  const regex = /^\$[a-zA-Z][a-zA-Z_0-9]*$/;
  return regex.test(name);
};
