// IntegrationDetailView.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import {
  connectIntegration,
  getIntegration,
  installIntegration,
  listGroups,
  listProviders,
  saveGroup,
} from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { useHistory, useLocation } from "react-router-dom";
import EditGroupDialog from "./edit-group-dialog";
import ConnectDialog from "../common/connect-dialog";
import Chip from "@mui/material/Chip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

const IntegrationDetailView = () => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrationData, setIntegrationData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get("id");

  useEffect(() => {
    handleLoadIntegration();
  }, [integrationId]);

  const handleLoadIntegration = async () => {
    const resp = await getIntegration(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      console.log(resp);
      setIntegrationData(resp);
    } else {
      errorToast("Error!");
    }
  };

  const handleLoadGroups = async () => {
    const resp = await listGroups(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      setIntegrationData((prev) => {
        const n = { ...prev, groups: resp.groups };
        return n;
      });
    } else {
      errorToast("Error!");
    }
  };

  const handleLoadProviders = async () => {
    const resp = await listProviders(
      apiCall,
      userInfo.activeScope.id,
      integrationId
    );
    if (resp) {
      setIntegrationData((prev) => {
        const n = { ...prev, providers: resp.providers };
        return n;
      });
    } else {
      errorToast("Error!");
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEntityClick = (entityId) => {
    // history.push(`/entity?id=${entityId}`);
  };

  const handleEditGroup = (group = null) => {
    setSelectedGroup(group);
    setGroupName(group ? group.name : "");
    setSelectedEntities(
      group ? group.entities.map((e) => `${e.integration}-${e.id}`) || [] : []
    );
    setGroupDialogOpen(true);
  };

  const handleToggleEntity = (entityId) => {
    setSelectedEntities((prev) =>
      prev.includes(entityId)
        ? prev.filter((id) => id !== entityId)
        : [...prev, entityId]
    );
  };

  const handleProviderAction = async (provider) => {
    if (provider.installed) {
      if (provider.installType === "CONNECT") {
        await handleConnect(provider.integration);
      } else {
        await handleInstall(provider.integration);
      }
    } else {
      if (provider.installType === "CONNECT") {
        await handleConnect(provider.integration);
      } else {
        await handleInstall(provider.integration);
      }
    }
  };

  const handleInstall = async (integration) => {
    const resp = await installIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Integration installed.");
      handleLoadIntegration();
    } else {
      errorToast("Error installing integration!");
    }
  };

  const handleConnect = async (integration) => {
    const resp = await connectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      setConnectInfo(resp);
      setConnectIntegrationName(integration);
      setOpenConnect(true);
    }
  };

  const handleSaveGroup = async () => {
    const group = {
      scopeId: userInfo.activeScope.id,
      groupId: null,
      name: groupName,
      integration: integrationId,
      entities: [],
    };
    if (selectedGroup) {
      group.groupId = selectedGroup.groupId;
    }
    selectedEntities.forEach((s) => {
      const integrationId = s.substring(0, s.indexOf("-"));
      const entityId = s.substring(s.indexOf("-") + 1);
      group.entities.push({ id: entityId, integration: integrationId });
    });
    console.log(group);

    if (!group.name) {
      errorToast("Name is required!");
      return;
    }
    if (group.entities.length === 0) {
      errorToast("No members are selected!");
      return;
    }

    const resp = await saveGroup(apiCall, group);
    if (resp) {
      successToast("Saved");
      handleLoadGroups();
    } else {
      errorToast("Error!");
    }
    // Save logic for the group (add or edit)
    setGroupDialogOpen(false);
    setGroupName("");
    setSelectedEntities([]);
  };

  const cardStyle = {
    width: "100%",
    cursor: "pointer",
    borderRadius: 1.5,
    transition: "all 0.2s ease-in-out",
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "12px",
    },
  };

  const availableTabs = [];
  if (integrationData?.entityEnabled) {
    availableTabs.push({
      label: "Entities",
      content: (
        <Grid container spacing={1.5}>
          {integrationData.entities.map((entity) => (
            <Grid item xs={6} sm={4} md={2} lg={2} key={entity.id}>
              <Card
                elevation={0}
                sx={{
                  ...cardStyle,
                  height: 90,
                  border: "none",
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={() => handleEntityClick(entity.id)}
              >
                <CardContent
                  sx={{
                    p: "12px !important",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.2,
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "2.4em", // Allows for 2 lines of text
                    }}
                  >
                    {entity.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    noWrap
                    sx={{
                      mt: 0.5,
                    }}
                  >
                    {entity.integration}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ),
    });
  }
  if (integrationData?.groupEnabled) {
    availableTabs.push({
      label: "Groups",
      content: (
        <Grid container spacing={1.5}>
          {" "}
          {/* Reduced spacing */}
          <Grid item xs={6} sm={4} md={2} lg={2}>
            {" "}
            {/* More items per row */}
            <Card
              elevation={0}
              sx={{
                ...cardStyle,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 90, // Fixed height for consistency
                backgroundColor: theme.palette.primary.main,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
              onClick={() => handleEditGroup()}
            >
              <CardContent sx={{ p: "12px !important", textAlign: "center" }}>
                <AddIcon sx={{ mb: 0.5 }} />
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: 1.2,
                  }}
                >
                  Create Group
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {integrationData.groups.map((group, index) => (
            <Grid item xs={6} sm={4} md={2} lg={2} key={index}>
              <Card
                elevation={0}
                sx={{
                  ...cardStyle,
                  height: 90,
                  backgroundColor: theme.palette.background.paper,
                  border: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={() => handleEditGroup(group)}
              >
                <CardContent
                  sx={{
                    p: "12px !important",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      textAlign: "center",
                      lineHeight: 1.2,
                    }}
                  >
                    {group.name || `Group ${index + 1}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    noWrap
                    sx={{
                      mt: 0.5,
                      textAlign: "center",
                    }}
                  >
                    {group.entities?.length || 0} entities
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ),
    });
  }
  if (integrationData?.providerEnabled) {
    availableTabs.push({
      label: "Providers",
      content: (
        <Grid container spacing={1.5}>
          {integrationData.providers.map((provider) => (
            <Grid item xs={6} sm={4} md={2} lg={2} key={provider.integration}>
              <Card
                elevation={0}
                sx={{
                  ...cardStyle,
                  height: 90,
                  border: "none",
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <CardContent
                  sx={{
                    p: "12px !important",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.2,
                    }}
                  >
                    {provider.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    noWrap
                    sx={{ mb: "auto" }}
                  >
                    {provider.org}
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      minWidth: "auto",
                      py: 0.25,
                      fontSize: "0.75rem",
                      fontWeight: 500,
                      backgroundColor: provider.installed
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: provider.installed
                          ? theme.palette.error.dark
                          : theme.palette.primary.dark,
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProviderAction(provider);
                    }}
                  >
                    {provider.installed ? "Uninstall" : "Install"}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ),
    });
  }

  return (
    <Box sx={{ padding: 2, pb: 7 }}>
      {integrationData && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h4">{integrationData.name}</Typography>
            {integrationData.installed ? (
              <Chip
                label="Installed"
                color="success"
                icon={<CheckCircleOutlineIcon />}
                sx={{
                  backgroundColor: theme.palette.background.l2,
                  color: "white",
                }}
              />
            ) : (
              <Chip
                label="Not Installed"
                color="error"
                sx={{
                  backgroundColor: theme.palette.background.l4,
                  color: "white",
                }}
              />
            )}
            {!integrationData.providerEnabled && !integrationData.installed && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleInstall(integrationData.integration)}
              >
                Install
              </Button>
            )}
          </Box>
          <Typography variant="subtitle1" color="textSecondary">
            {integrationData.org}
          </Typography>

          {/* Render the content of the active tab */}
          <Box sx={{ mt: 4 }}>{availableTabs[tabIndex]?.content}</Box>

          {/* Tab Navigation */}
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: theme.palette.background.paper,
              borderTop: 1,
              borderColor: "divider",
              zIndex: 1100,
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "medium",
                  minWidth: 120,
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {/* Group Edit Dialog */}
          <EditGroupDialog
            open={groupDialogOpen}
            onClose={() => setGroupDialogOpen(false)}
            onSave={handleSaveGroup}
            groupName={groupName}
            setGroupName={setGroupName}
            entities={integrationData.entities}
            selectedEntities={selectedEntities}
            onToggleEntity={handleToggleEntity}
          />
          <ConnectDialog
            open={openConnect}
            integration={connectIntegrationName}
            connectInfo={connectInfo}
            onClose={() => setOpenConnect(false)}
            onSuccess={() => successToast("Linked!")}
            onFailure={() => errorToast("Link error!")}
          />
        </>
      )}
    </Box>
  );
};

export default IntegrationDetailView;
