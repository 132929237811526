import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useHistory } from "react-router-dom";
import SearchableSelectInput from "../input/searchable-select-input";
import NumberInput from "../input/number-input";
import TimeInput from "../input/time-input";
import DateInput from "../input/date-input";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import FixedSelectInput from "../input/fixed-select-input";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { searchableSelectCheck } from "../input/searchable-select-check";
import { numberCheck } from "../input/number-check";
import { fixedSelectCheck } from "../input/fixed-select-check";
import {
  connectIntegration,
  deleteAutomation,
  disableAutomation,
  enableAutomation,
  getAutomation,
  initAutomationEditing,
  saveAutomationName,
  runningAutomation,
} from "./automation-service";
import Typography from "@mui/material/Typography";
import Markdown from "../common/markdown";
import NumberUnitInput from "../input/number-unit-input";
import { numberUnitCheck } from "../input/number-unit-check";
import { timeCheck } from "../input/time-check";
import { dateCheck } from "../input/date-check";
import { textCheck } from "../input/text-check";
import TextInput from "../input/text-input";
import DeleteAutomationDialog from "./delete-automation-dialog";
import { useToast } from "../common/toast";
import ConnectDialog from "../common/connect-dialog";
import { useTheme } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CodeEditor from "../common/code-editor";
import { useWebSocket } from "../ws/websocket";
import TextField from "@mui/material/TextField";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import useApiCall from "../common/api-call";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import { useEventEmitter } from "../ws/event-context";
import MSwitch from "../common/switch";

const getSelector = (spec) => {
  if (spec.hasEnums) {
    return "FIXED_SELECT";
  } else if (spec.type === "String") {
    return "TEXT";
  } else if (spec.type === "Number") {
    return "NUMBER";
  } else if (spec.type === "Duration") {
    return "NUMBER_UNIT";
  } else if (spec.type === "Time") {
    return "TIME";
  } else if (spec.type === "SpecialTime") {
    return "FIXED_SELECT";
  } else if (spec.type === "SpecialDate") {
    return "FIXED_SELECT";
  } else if (spec.type === "Date") {
    return "DATE";
  } else {
    return "DYNAMIC_SELECT";
  }
};

const checkerMap = {
  DYNAMIC_SELECT: searchableSelectCheck,
  FIXED_SELECT: fixedSelectCheck,
  NUMBER: numberCheck,
  NUMBER_UNIT: numberUnitCheck,
  TIME: timeCheck,
  DATE: dateCheck,
  TEXT: textCheck,
};

const varValueCheck = (varName, varSpec) => {
  if (!varSpec || varSpec.empty) {
    return false;
  }
  return varSpec.valid;
};

const DataSelector = ({
  varName,
  spec,
  onSave,
  onCancel,
  onFetch,
  onConnect,
}) => {
  const selectMap = {
    FIXED_SELECT: (f, idx) => (
      <FixedSelectInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        onError={(error) => handleErrorChange(idx, error)}
      ></FixedSelectInput>
    ),
    DYNAMIC_SELECT: (f, idx) => (
      <SearchableSelectInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        onError={(error) => handleErrorChange(idx, error)}
        fetchValueSpec={dynamicValueSpecFetch}
      ></SearchableSelectInput>
    ),
    NUMBER: (f, idx) => (
      <NumberInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
        onError={(error) => handleErrorChange(idx, error)}
      ></NumberInput>
    ),
    TIME: (f, idx) => (
      <TimeInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
      ></TimeInput>
    ),
    DATE: (f, idx) => (
      <DateInput
        valueSpec={f}
        onChange={(value) => handleValueChange(f, idx, value)}
      ></DateInput>
    ),
    TEXT: (f, idx) => (
      <Box
        sx={{
          height: "30px",
        }}
      >
        <TextInput
          valueSpec={f}
          onChange={(value) => handleValueChange(f, idx, value)}
        ></TextInput>
      </Box>
    ),
    NUMBER_UNIT: (f, idx) => (
      <NumberUnitInput
        valueSpec={f}
        onChange={(value, unit) =>
          handleValueChange(f, idx, value.toString() + unit)
        }
        onError={(error) => handleErrorChange(idx, error)}
      ></NumberUnitInput>
    ),
  };

  const [selector, setSelector] = useState(null);
  const [valueSpecs, setValueSpecs] = useState([]);
  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setSelector(getSelector(spec));
    const vs = [];
    const vals = [];
    const errs = [];
    for (const item of spec.items) {
      const value = item.value;
      const valueSpec = item.valueSpec;
      const v = populateValueSpec(value, valueSpec);
      vals.push(item.value);
      vs.push(v);
      errs.push(false);
    }
    setValues(vals);
    setErrors(errs);
    setValueSpecs(vs);
  }, []);

  const handleValueChange = (valueSpec, idx, newValue) => {
    values[idx] = newValue;
    valueSpecs[idx].value = newValue;
  };

  const handleErrorChange = (idx, err) => {
    errors[idx] = err;
  };

  const dynamicValueSpecFetch = async (inputValue) => {
    const varData = {
      var: varName,
      input: inputValue,
    };
    return await onFetch(varData);
  };

  const handleAdd = async () => {
    const vs = await dynamicValueSpecFetch("");
    const value = vs.value;
    const v = populateValueSpec(value, vs.valueSpec);
    setValues([...values, value]);
    setErrors([...errors, false]);
    setValueSpecs([...valueSpecs, v]);
  };

  const populateValueSpec = (value, valueSpec) => {
    const v = { ...valueSpec, value };
    return v;
  };

  const handleDelete = (idx) => {
    const tValues = [...values];
    tValues.splice(idx, 1);
    const tValueSpecs = [...valueSpecs];
    tValueSpecs.splice(idx, 1);
    const tErrors = [...errors];
    tErrors.splice(idx, 1);
    setValues(tValues);
    setValueSpecs(tValueSpecs);
    setErrors(tErrors);
  };

  const handleSave = async () => {
    if (values.length === 0 || errors.indexOf(true) !== -1) {
      return;
    }
    const varData = {
      var: varName,
      inputs: [...values],
    };
    await onSave(varData);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConnect = async () => {
    const parts = spec.type.split(".");
    const integration = parts[0] + "." + parts[1];
    await onConnect(integration);
  };

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        {spec.connect && (
          <Grid item xs={1}>
            <Button size="medium" variant="outlined" onClick={handleConnect}>
              Link New Account
            </Button>
          </Grid>
        )}
        {selectMap[selector] !== undefined && (
          <>
            {valueSpecs.map((valueSpec, idx) => (
              <Grid item xs={1} key={`param-${varName}-${idx}`}>
                <Grid container columns={{ xs: 12 }}>
                  {spec.repeated && (
                    <Grid item xs="auto">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(idx)}
                        disabled={valueSpecs.length <= 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item xs>
                    {selectMap[selector](valueSpec, idx)}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        {spec.repeated && (
          <Grid item xs={1}>
            <Button size="medium" fullWidth onClick={handleAdd}>
              <AddIcon />
            </Button>
          </Grid>
        )}
        <Grid item xs={1}>
          <Grid
            container
            columns={{ xs: 12 }}
            rowSpacing={2}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Button
                size="medium"
                variant="outlined"
                fullWidth
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="medium"
                variant="contained"
                fullWidth
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const AutomationEditCardContent = ({ params, onSave, onDelete, editable }) => {
  const [edit, setEdit] = useState(false);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedVar, setSelectedVar] = useState("");
  const [loading, setLoading] = useState(true);
  const [script, setScript] = useState("");
  const [varSaving, setVarSaving] = useState(false);
  const [userInfo] = useRecoilState(userInfoState);
  const history = useHistory();
  const { sendAndWaitWs } = useWebSocket();
  const [spinButton, setSpinButton] = useState(null);
  const { apiCall } = useApiCall();

  const [sessionId, setSessionId] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [running, setRunning] = useState(false);
  const [runnable, setRunnable] = useState(false);
  const [automationName, setAutomationName] = useState(null);
  const [automationDescription, setAutomationDescription] = useState(null);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [connectConfirm, setConnectConfirm] = useState(false);
  const [vars, setVars] = useState({});
  const [unsaved, setUnsaved] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [type, setType] = useState(null);
  const [timestamp, setTimestamp] = useState(0);
  const [nameError, setNameError] = useState(false);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const { ws, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    if (params && sendAndWaitWs) {
      if (params.draft || params.premadeId) {
        initEdit(
          params.automationId,
          params.premadeId,
          params.premadeVersion,
          params.draft
        );
      } else {
        initRead(params.automationId);
      }
    }
    return () => {};
  }, [params]);

  useEffect(() => {
    if (automationName && automationName.trim().toLowerCase() === "this") {
      setNameError(true);
      setTitleEditMode(true);
    } else {
      setNameError(false);
    }
  }, [automationName]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "/automation/history/update") {
        const payload = JSON.parse(data.payload);
        if (payload) {
          setTimestamp(payload.timestamp);
        }
      } else if (target === "/automation/runningStatus/update") {
        const payload = JSON.parse(data.payload);
        if (payload) {
          setRunning(payload.running);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [params]);

  const enterEdit = async () => {
    if (edit) {
      return;
    }
    setEdit(true);
    initEdit(
      params.automationId,
      params.premadeId,
      params.premadeVersion,
      params.draft
    );
  };

  const initRead = async (automationId) => {
    setLoading(true);
    const resp = await getAutomation(
      apiCall,
      userInfo.activeScope.id,
      automationId
    );
    if (resp) {
      const automationOverview = resp;
      setAutomationName(automationOverview["name"]);
      setAutomationDescription(resp["description"]);
      setRunnable(automationOverview["runnable"]);
      setEnabled(automationOverview["enabled"]);
      setRunning(automationOverview["running"]);
      setScript(automationOverview["script"]);
      setType(automationOverview["type"]);
      if (automationOverview["automationHistory"]) {
        setTimestamp(automationOverview["automationHistory"]["timestamp"]);
      }
      setLoading(false);
      setEdit(false);
    } else {
      errorToast("Error loading automation.");
    }
  };

  const initEdit = async (automationId, premadeId, premadeVersion, draft) => {
    setLoading(true);
    const resp = await initAutomationEditing(
      sendAndWaitWs,
      automationId,
      premadeId,
      premadeVersion,
      draft
    );

    if (resp) {
      const automationOverview = resp.automationOverview;
      setSessionId(resp["sessionId"]);
      setAutomationName(automationOverview["name"]);
      setAutomationDescription(automationOverview["description"]);
      setVars(automationOverview["vars"]);
      setRunnable(automationOverview["runnable"]);
      setEnabled(automationOverview["enabled"]);
      setRunning(automationOverview["running"]);
      setLoading(false);
      setEdit(true);
      if (premadeId) {
        setUnsaved(true);
      }
      if (draft) {
        setUnsaved(true);
        setScript(draft);
      }
    } else {
      errorToast("Error loading automation.");
    }
  };

  const handleVarValueSave = async (varData) => {
    try {
      setVarSaving(true);
      setUnsaved(true);
      const response = await sendAndWaitWs(
        "/automation/overview/update-config",
        JSON.stringify({ ...varData, sessionId: sessionId })
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      setAutomationDescription(jsonData["description"]);
      setVars(jsonData["vars"]);
      setVarSaving(false);
      setShowDrawer(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVarValueFetch = async (varData) => {
    try {
      const response = await sendAndWaitWs(
        "/automation/overview/provide-config",
        JSON.stringify({ ...varData, sessionId: sessionId })
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleAutomationNameSave = async () => {
    try {
      const response = await saveAutomationName(
        apiCall,
        userInfo.activeScope.id,
        params.automationId,
        automationName
      );
      if (response.error) {
        throw new Error(response.error);
      }
      successToast("Name Saved");
    } catch (error) {
      errorToast("Error!");
      console.error("Error saving name:", error);
    }
  };

  const handleAutomationSave = async () => {
    try {
      setLoading(true);
      let response = null;
      if (params.automationId) {
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId: sessionId,
            automationId: params.automationId,
          })
        );
      } else if (params.premadeId) {
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId: sessionId,
            premadeId: params.premadeId,
            premadeVersion: params.premadeVersion,
            preferredName: automationName,
          })
        );
      } else {
        // draft
        response = await sendAndWaitWs(
          "/automation/overview/save",
          JSON.stringify({
            sessionId: sessionId,
            preferredName: automationName,
          })
        );
      }

      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      setRunnable(jsonData["runnable"]);
      setUnsaved(false);
      successToast("Saved");
      onSave(jsonData.automationId);
    } catch (error) {
      errorToast("Save error!");
      console.error("Error saving data:", error);
      setLoading(false);
    }
  };

  const handleEnableDisable = async (event, enableDisable) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Enabled.");
        setEnabled(true);
      } else {
        errorToast("Error to enable the automation.");
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Disabled.");
        setEnabled(false);
        // window.location.reload();
      } else {
        errorToast("Error to disable the automation.");
      }
    }
  };
  const handleStartTaskFlow = async (event, running) => {
    if (running) {
      const success = await runningAutomation(
        apiCall,
        userInfo.activeScope.id,
        params.automationId
      );
      if (success) {
        successToast("Task flow started.");
      } else {
        errorToast("Error starting the task flow.");
      }
    }
  };

  const handleDelete = async () => {
    const success = await deleteAutomation(
      apiCall,
      userInfo.activeScope.id,
      params.automationId,
      true
    );
    if (success) {
      onDelete(params.automationId);
    } else {
      errorToast("Error. Please retry.");
    }
  };

  const handleConnect = async (integration) => {
    const resp = await connectIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    console.log(resp);
    if (resp) {
      setConnectInfo(resp);
      setConnectIntegrationName(integration);
      setConnectConfirm(true);
    }
  };

  const handleMarkdownLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
      const v =
        "$" +
        event.target.href.substring(event.target.href.lastIndexOf("#") + 1);
      setSelectedVar(v);
      setShowDrawer(true);
    };

    const validStyle = {
      color: theme.palette.text.validInput,
      fontWeight: "bold",
      textDecoration: "underline",
    };
    const invalidStyle = {
      color: theme.palette.text.invalid,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: theme.palette.text.invalid,
    };
    const varName = "$" + href.substring(href.lastIndexOf("#") + 1);
    const valid = varValueCheck(varName, vars[varName]);
    return (
      <a
        href={href}
        onClick={handleClick}
        style={valid ? validStyle : invalidStyle}
      >
        {children}
      </a>
    );
  };

  const handleMarkdownNonEditLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
    };
    const dStyle = {
      color: theme.palette.text.disabledInput,
      fontWeight: "bold",
      textDecoration: "underline",
    };
    return (
      <a href={href} onClick={handleClick} style={dStyle}>
        {children}
      </a>
    );
  };

  const handleJumpClick = (buttonType, path) => {
    console.log(path);
    setSpinButton(buttonType);
    history.push(path);
    setSpinButton(null);
  };

  const handleTitleEditToggle = () => {
    if (!nameError) {
      setTitleEditMode(!titleEditMode);
    }
    if (titleEditMode) {
      if (params.automationId) {
        // can update name only if the automation already exists
        handleAutomationNameSave();
      }
    }
    setTitleEditMode(!titleEditMode);
  };

  return (
    <>
      {loading ? (
        <CardContent>
          <LoadingSpinner />
        </CardContent>
      ) : (
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "8px !important",
            backgroundColor: theme.palette.background.card,
          }}
        >
          <Box display="flex" alignItems="center" mb={2} mt={2}>
            {!params.automationId || titleEditMode ? (
              <TextField
                value={automationName}
                onChange={(e) => setAutomationName(e.target.value)}
                fullWidth
                variant="outlined"
                size="large"
                sx={{ marginRight: 1 }}
                error={nameError}
                helperText={nameError ? "Name cannot be 'this'." : ""}
              />
            ) : (
              <Typography variant="h5">{automationName}</Typography>
            )}
            <IconButton
              onClick={handleTitleEditToggle}
              sx={{ color: theme.palette.text.primary }}
              disabled={nameError || automationName.trim() === ""}
            >
              {titleEditMode ? <EditOffIcon /> : <EditIcon />}
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleAutomationSave}
                  disabled={!unsaved || spinButton !== null}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                {runnable && params.automationId ? (
                  type == "RECURRING" ? (
                    <MSwitch
                      checked={enabled}
                      disabled={
                        !runnable || unsaved || edit || spinButton !== null
                      }
                      onChange={handleEnableDisable}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  ) : running ? (
                    <CircularProgress size={24} />
                  ) : (
                    <PlayCircleFilledWhiteIcon
                      color={runnable ? "primary" : "disabled"}
                      disabled={!runnable}
                      onClick={(e) => {
                        handleStartTaskFlow(e, true);
                        e.stopPropagation();
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )
                ) : (
                  <ErrorIcon style={{ color: "red" }} />
                )}
              </Grid>
              {params.automationId && (
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    sx={{
                      color: theme.palette.background.l4,
                    }}
                    disabled={spinButton !== null}
                    onClick={() => setDeleteConfirm(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
              {params.automationId && userInfo.setting.scriptMode && (
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    // onClick={() => openCodeView(params.automationId)}
                    onClick={() =>
                      handleJumpClick(
                        "script",
                        "/automation/script?id=" + params.automationId
                      )
                    }
                    disabled={spinButton !== null}
                  >
                    Script
                    {spinButton === "script" && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </Grid>
              )}
              {params.automationId && userInfo.setting.debugMode && (
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      handleJumpClick(
                        "debug",
                        "/automation/debug?id=" + params.automationId
                      )
                    }
                    disabled={spinButton !== null}
                  >
                    Debug
                    {spinButton === "debug" && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
          {timestamp !== 0 && (
            <Box>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginTop: "5px",
                  color: theme.palette.text.gray,
                }}
              >
                {customFormat(dayjs(timestamp), currentTime)}
              </Typography>
            </Box>
          )}
          <Box
            mt={2}
            mb={2}
            sx={{
              backgroundColor:
                edit || !editable
                  ? theme.palette.background.card
                  : theme.palette.background.l1,
              cursor: edit || !editable ? "default" : "pointer",
              padding: "8px",
              borderRadius: "4px",
            }}
            onClick={() => enterEdit()}
          >
            <Markdown
              components={{
                a:
                  edit && editable
                    ? handleMarkdownLink
                    : handleMarkdownNonEditLink,
              }}
            >
              {automationDescription}
            </Markdown>
          </Box>
          {userInfo.setting.scriptMode && (
            <Accordion sx={{ backgroundColor: theme.palette.background.l1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Script</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CodeEditor value={script} editable={false} height="400px" />
              </AccordionDetails>
            </Accordion>
          )}
        </CardContent>
      )}
      <Drawer anchor="bottom" open={showDrawer} onClose={handleDrawerClose}>
        <Box p={2}>
          {varSaving ? (
            <LoadingSpinner />
          ) : (
            <DataSelector
              varName={selectedVar}
              spec={vars[selectedVar]}
              onSave={handleVarValueSave}
              onCancel={handleDrawerClose}
              onFetch={handleVarValueFetch}
              onConnect={handleConnect}
            ></DataSelector>
          )}
        </Box>
      </Drawer>
      <DeleteAutomationDialog
        open={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        onDelete={handleDelete}
      ></DeleteAutomationDialog>
      <ConnectDialog
        open={connectConfirm}
        integration={connectIntegrationName}
        connectInfo={connectInfo}
        onClose={() => setConnectConfirm(false)}
        onSuccess={() => successToast("Linked!")}
        onFailure={() => errorToast("Link Error!")}
      ></ConnectDialog>
    </>
  );
};

export default AutomationEditCardContent;
