import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { listIntegrations } from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import ConnectDialog from "../common/connect-dialog";
import Grid from "@mui/material/Grid";

const IntegrationListView = () => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrations, setIntegrations] = useState([]);
  const [connectIntegrationName, setConnectIntegrationName] = useState("");
  const [connectInfo, setConnectInfo] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    handleLoadIntegrations();
  }, []);

  const handleLoadIntegrations = async () => {
    const resp = await listIntegrations(apiCall, userInfo.activeScope.id);
    console.log(resp);
    if (resp) {
      setIntegrations(resp.integrations);
    } else {
      errorToast("Error loading integrations!");
    }
  };

  const handleCardClick = (integration) => {
    const integrationId = integration.integration;
    history.push(`/integration?id=${integrationId}`);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {integrations.map((integration) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={integration.integration}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                padding: 1.5, // Reduced padding
                cursor: "pointer",
                backgroundColor: integration.installed
                  ? theme.palette.background.paper
                  : theme.palette.background.disabled,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
              onClick={() => handleCardClick(integration)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5, // Reduced margin
                }}
              >
                <Typography
                  variant="subtitle1" // Smaller text
                  noWrap
                  sx={{
                    fontWeight: "medium",
                    flex: 1,
                    mr: 1,
                  }}
                >
                  {integration.name}
                </Typography>
                {integration.installed && (
                  <DownloadDoneIcon
                    color="success"
                    sx={{ fontSize: 20 }} // Smaller icon
                  />
                )}
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontSize: "0.75rem", // Smaller text
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {integration.org}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ConnectDialog
        open={openConnect}
        integration={connectIntegrationName}
        connectInfo={connectInfo}
        onClose={() => setOpenConnect(false)}
        onSuccess={() => successToast("Linked!")}
        onFailure={() => errorToast("Link error!")}
      />
    </Box>
  );
};

export default IntegrationListView;
