import React, { useEffect, useRef, useState } from "react";

import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/material.css";
import "codemirror/theme/solarized.css";
import "codemirror/mode/yaml/yaml";
import CodeMirror from "codemirror";

import { getContext } from "../lang/yaml-context";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import tippy from "tippy.js";

import "./code-editor.css";

const CodeEditor = ({
  value = "",
  editable = true,
  onChange = null,
  onPreParse = null,
  specDataRef = null,
  parseResults = [],
  height = null,
}) => {
  const codeMirrorRef = useRef(null);

  const [preParseResults, setPreParseResults] = useState([]);

  useEffect(() => {
    let editor;
    if (codeMirrorRef.current && !codeMirrorRef.current.editor) {
      editor = CodeMirror(codeMirrorRef.current, {
        value: value,
        readOnly: !editable,
        mode: "yaml",
        // theme: "solarized light",
        // theme: "material",
        theme: "idea",
        lineNumbers: true,
        tabSize: 2,
        indentWithTabs: false,
        indentUnit: 2,
        smartIndent: true,
        lineWrapping: true,
        extraKeys: {
          Tab: (cm) => {
            if (cm.somethingSelected()) {
              cm.indentSelection("add");
            } else {
              cm.replaceSelection(
                Array(cm.getOption("indentUnit") + 1).join(" "),
                "end",
                "+input"
              );
            }
          },
          // 'Ctrl-Space': () => {
          //     return false;
          // },
        },
        hintOptions: {
          completeSingle: false,
        },
      });

      if (height) {
        editor.getWrapperElement().style.height = height;
      }

      codeMirrorRef.current.editor = editor;
    } else {
      return () => {};
    }

    let isTyping = false;

    editor.on("changes", async (e) => {
      isTyping = true;
      if (specDataRef && specDataRef.current) {
        const res = await preParse(e.getCursor(), e.getValue(), true);
        setPreParseResults(res.errors);
        showHints(res);
        if (onPreParse) {
          onPreParse(res);
        }
      }
      if (onChange) {
        onChange(e);
      }
    });

    editor.on("endCompletion", async (e) => {
      const res = await preParse(e.getCursor(), e.getValue(), true);
      if (res.suggestion.fill) {
        editor.replaceRange(res.suggestion.fill, e.getCursor());
      }
      if (onPreParse) {
        onPreParse(res);
      }
    });

    if (specDataRef && specDataRef.current) {
      // editor.on('cursorActivity', async (e) => {
      //     setTimeout(async () => {
      //         if (isTyping) {
      //             isTyping = false;
      //             return;
      //         }
      //         const res = await preParse(e.getCursor(), e.getValue(), false);
      //         setPreParseResults(res.errors);
      //         isTyping = false;
      //         if (onPreParse) {
      //             onPreParse(res);
      //         }
      //     }, 0);
      // });
    }

    return () => {
      // if (codeMirrorRef.current && codeMirrorRef.current.editor) {
      // }
    };
  }, []);

  const showHints = (res) => {
    const row = res.suggestion.row;
    if (row !== null && row !== undefined) {
      const start = res.suggestion.start;
      const end = res.suggestion.end;
      let options = [];
      if (res.suggestion.options) {
        options = res.suggestion.options;
      }
      CodeMirror.showHint(codeMirrorRef.current.editor, () => {
        return {
          list: options,
          from: CodeMirror.Pos(row, start),
          to: CodeMirror.Pos(row, end),
        };
      });
    }
  };

  const preParse = (pos, code, typing) => {
    return getContext(
      code,
      {
        row: pos.line,
        col: pos.ch,
      },
      specDataRef.current,
      typing
    );
  };

  useEffect(() => {
    if (codeMirrorRef.current.editor) {
      codeMirrorRef.current.editor.setOption("readOnly", !editable);
    }
  }, [editable]);

  useEffect(() => {
    if (
      codeMirrorRef.current.editor &&
      codeMirrorRef.current.editor.getValue() !== value
    ) {
      codeMirrorRef.current.editor.setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (codeMirrorRef.current.editor) {
      const editor = codeMirrorRef.current.editor;
      const marks = editor.getAllMarks();
      for (const mark of marks) {
        mark.clear();
      }
      let cnt = 0;
      for (let pr of preParseResults) {
        cnt++;
        const start = { line: pr["start"]["row"], ch: pr["start"]["col"] };
        const end = { line: pr["end"]["row"], ch: pr["end"]["col"] };
        editor.markText(start, end, {
          className: `error-underline error-tooltip-${cnt}`,
        });
        tippy(`.error-tooltip-${cnt}`, {
          content: pr["description"],
          placement: "bottom",
        });
      }
      for (let pr of parseResults) {
        cnt++;
        const start = { line: pr["start"]["row"] - 1, ch: pr["start"]["col"] };
        const end = { line: pr["end"]["row"] - 1, ch: pr["end"]["col"] + 1 };
        editor.markText(start, end, {
          className: `error-underline error-tooltip-${cnt}`,
        });
        tippy(`.error-tooltip-${cnt}`, {
          content: pr["description"],
          placement: "bottom",
        });
      }
    }
  }, [preParseResults, parseResults]);

  return <div id="div" ref={codeMirrorRef}></div>;
};

export default CodeEditor;
