import CodeEditor from "../common/code-editor";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { developerIdTokenState, userInfoState } from "../global-state";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import LoadingSpinner from "../common/loading";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import DeletePremadeDialog from "./delete-premade-dialog";
import Chip from "@mui/material/Chip";
import {
  aiDescribePremade,
  archivePremade,
  buildPremade,
  deletePremade,
  formatPremade,
  getPremade,
  getPremadeExample,
  listPremadeExamples,
  publishPremade,
  savePremade,
} from "./premade-service";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Markdown from "../common/markdown";
import { useToast } from "../common/toast";
import { useTheme } from "@mui/material/styles";
import useApiCall from "../common/api-call";

const PremadeScriptView = () => {
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { successToast, warningToast, errorToast } = useToast();
  const history = useHistory();
  const [spinButton, setSpinButton] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  const [queryParams, setQueryParams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [premade, setPremade] = useState(null);
  const [premadeId, setPremadeId] = useState(null);
  const [version, setVersion] = useState("");
  const [versions, setVersions] = useState([]);
  const [code, setCode] = useState("");
  const [parseResults, setParseResults] = useState([]);
  const [unsaved, setUnsaved] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [descriptions, setDescriptions] = useState([]);
  const [buildDescription, setBuildDescription] = useState("");
  const [buildSummary, setBuildSummary] = useState("");
  const { apiCall } = useApiCall();
  const theme = useTheme();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
    if (params["id"]) {
      loadPremades(params);
    } else {
      setPremade({ archived: false, version: "draft" });
      setVersion("draft");
      setLoading(false);
    }
  }, []);

  const loadPremades = async (params) => {
    setLoading(true);
    const result = await getPremade(apiCall, params["id"]);
    if (result !== null) {
      const premade = findVersion(result, params["version"]);
      if (premade !== null) {
        setPremade(premade);
        setPremadeId(premade["id"]);
        setVersion(premade["version"]);
        setCode(premade["script"]);
        setParseResults(premade["parseResults"]);
        setDescriptions(premade["descriptions"]);
        setVersions(result.map((p) => p["version"]));
        history.replace(
          `/premade/script?id=${premade.id}&version=${premade.version}`
        );
        setLoading(false);
      }
    }
  };

  const findVersion = (ps, v) => {
    if (ps.length === 0) {
      return null;
    }
    const found = ps.filter((p) => p["version"] === v);
    if (found.length > 0) {
      return found[0];
    }
    const foundDraft = ps.filter((p) => p["version"] === "draft");
    if (foundDraft.length > 0) {
      return foundDraft[0];
    }
    return ps.reduce((max, p) => {
      return p["version"] > max["version"] ? p : max;
    }, ps[0]);
  };

  const [infoType, setInfoType] = useState(0);
  const handleInfoTypeChange = (event, newValue) => {
    setInfoType(newValue);
  };

  const handleSave = async () => {
    setSpinButton("save");
    const resp = await savePremade(
      apiCall,
      premadeId,
      code,
      descriptions.map((d) => d.description)
    );
    if (resp != null) {
      setPremade(resp);
      setPremadeId(resp.id);
      setVersion(resp.version);
      setParseResults(resp.parseResults);
      successToast("Saved!");
      setUnsaved(false);
      await loadPremades({ id: resp.id, version: resp.version });
    } else {
      errorToast("Save Failed!");
    }
    setSpinButton(null);
  };

  const handlePublish = async () => {
    setSpinButton("publish");
    const resp = await publishPremade(apiCall, premadeId);
    if (resp) {
      successToast("Published!");
      await loadPremades({ id: resp.id, version: resp.version });
      // history.replace(`/app/premade/script?id=${resp.id}&version=${resp.version}`);
    } else {
      errorToast("Publish Failed!");
    }
    setSpinButton(null);
  };

  const hanldeArchive = async () => {
    const resp = await archivePremade(apiCall, premadeId, version);
    if (resp) {
      successToast("Archived!");
      await loadPremades({ id: premadeId, version: version });
    } else {
      errorToast("Archive Failed!");
    }
  };

  const handleBuild = async () => {
    const resp = await buildPremade(apiCall, code);
    if (resp) {
      setBuildDescription(resp.description);
      setParseResults(resp.parseResults);
      if (resp.parseResults.length > 0) {
        warningToast("Build Issue");
      } else {
        successToast("Success");
        setBuildSummary("Build SUCCESS");
      }
    } else {
      errorToast("Error!");
    }
  };

  const handleFormat = async () => {
    const resp = await formatPremade(apiCall, code);
    if (resp) {
      setBuildDescription("");
      setParseResults(resp.parseResults);
      if (resp.parseResults.length === 0) {
        successToast("Success");
        setCode(resp.script);
        setBuildSummary("Format SUCCESS");
      } else {
        warningToast("Format Issue");
      }
    } else {
      errorToast("Error!");
      setBuildSummary("Format ERROR");
    }
  };

  const handleAiDescribe = async () => {
    const resp = await aiDescribePremade(apiCall, code);
    if (resp) {
      setBuildDescription("");
      successToast("Success");
      setCode(resp.script);
      setBuildSummary("Generate and and description SUCCESS");
    } else {
      errorToast("Error!");
      setBuildSummary("Generate and and description ERROR");
    }
  };

  const handleDelete = async () => {
    const success = await deletePremade(apiCall, premadeId);
    if (success) {
      history.push("/premade/gallery");
    } else {
      errorToast("Error. Please retry.");
    }
  };

  const handleDescriptionChange = (descs) => {
    const newDesc = [...descs].filter((x) => x !== "");
    setDescriptions(newDesc);
    setUnsaved(true);
  };

  const handleApplyExample = (example) => {
    if (example) {
      setDescriptions(example.descriptions);
      setParseResults(example.parseResults);
      setCode(example.script);
      setInfoType(0);
    }
  };

  const handleVersionChange = async (event) => {
    await loadPremades({ id: premadeId, version: event.target.value });
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ flexGrow: 1, height: "calc(100vh - 64px)" }} margin={2}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={1}>
              <FormControl fullWidth>
                <InputLabel id="version-select">Select Version</InputLabel>
                <Select
                  sx={{ backgroundColor: theme.palette.background.input }}
                  labelId="version-select"
                  id="version-select"
                  value={version}
                  label="Select Version"
                  onChange={handleVersionChange}
                >
                  {versions.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip
                    label={
                      premade.archived
                        ? "Archived"
                        : version === "draft"
                        ? "Draft"
                        : "Active"
                    }
                    color={
                      premade.archived
                        ? "error"
                        : version === "draft"
                        ? "warning"
                        : "success"
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!unsaved || spinButton !== null}
                  >
                    Save
                    {spinButton === "save" && (
                      <CircularProgress
                        size={20}
                        style={{
                          marginLeft: 10,
                          color: theme.palette.background.c0,
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  {premade.version === "draft" && (
                    <Button
                      variant="contained"
                      onClick={handlePublish}
                      disabled={
                        unsaved ||
                        version !== "draft" ||
                        !premadeId ||
                        spinButton !== null
                      }
                    >
                      Publish
                      {spinButton === "publish" && (
                        <CircularProgress
                          size={20}
                          style={{
                            marginLeft: 10,
                            color: theme.palette.background.c0,
                          }}
                        />
                      )}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {premade.version !== "draft" && (
                    <Button
                      variant="contained"
                      onClick={hanldeArchive}
                      disabled={
                        premade.archived ||
                        version === "draft" ||
                        spinButton !== null
                      }
                    >
                      Archive
                    </Button>
                  )}
                </Grid>
                {premade.version === "draft" && (
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setDeleteConfirm(true)}
                      disabled={version !== "draft"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/*<Grid item xs={1}>*/}
            {/*    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>*/}
            {/*        {premade.summary || '[Summary]'}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item xs>
              <Grid container spacing={4} columns={{ xs: 12 }}>
                <Grid item xs={6}>
                  <CodeEditor
                    value={code}
                    editable={true}
                    parseResults={parseResults}
                    height={"calc(100vh - 200px)"}
                    onChange={(instance) => {
                      const code = instance.getValue();
                      setCode(code);
                      setUnsaved(true);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={infoType}
                            onChange={handleInfoTypeChange}
                            aria-label="Info type"
                          >
                            <Tab label="Summary" />
                            <Tab label="Build" />
                            <Tab label="Statistics" />
                            <Tab label="Example" />
                          </Tabs>
                        </Box>
                        {infoType === 0 && (
                          <SummaryTab
                            descs={descriptions}
                            onChange={handleDescriptionChange}
                          />
                        )}
                        {infoType === 1 && (
                          <BuildTab
                            descriptionPreview={buildDescription}
                            summary={buildSummary}
                            onBuild={handleBuild}
                            onFormat={handleFormat}
                            onDescribe={handleAiDescribe}
                            parseResults={parseResults}
                          ></BuildTab>
                        )}
                        {infoType === 2 && <div>Statistics</div>}
                        {infoType === 3 && (
                          <ExampleTab onApply={handleApplyExample}></ExampleTab>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DeletePremadeDialog
            open={deleteConfirm}
            onClose={() => setDeleteConfirm(false)}
            onDelete={handleDelete}
          ></DeletePremadeDialog>
        </Box>
      )}
    </>
  );
};

const BuildTab = ({
  summary,
  descriptionPreview,
  parseResults,
  onBuild,
  onFormat,
  onDescribe,
}) => {
  const [buildType, setBuildType] = useState(0);
  const theme = useTheme();

  const handleBuildTypeChange = (event, newValue) => {
    setBuildType(newValue);
  };

  const handleMarkdownLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
      // TODO: show a dialog
    };
    return (
      <a href={href} onClick={handleClick}>
        {children}
      </a>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, overflowY: "auto", p: 3 }}>
      <Box>
        <Box sx={{ p: 1 }}>
          <Button variant="contained" onClick={onBuild}>
            Build
          </Button>
        </Box>
        <Box sx={{ p: 1 }}>
          <Button variant="contained" onClick={onFormat}>
            Format
          </Button>
        </Box>
        <Box sx={{ p: 1 }}>
          <Button variant="contained" onClick={onDescribe}>
            AI Describe
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={buildType}
            onChange={handleBuildTypeChange}
            aria-label="Info type"
          >
            <Tab label="Description Preview" />
            <Tab label="Build Results" />
          </Tabs>
        </Box>
        {buildType === 0 && (
          <Box sx={{ flexGrow: 1 }}>
            <Markdown components={{ a: handleMarkdownLink }}>
              {descriptionPreview}
            </Markdown>
          </Box>
        )}
        {buildType === 1 && (
          <Box>
            {parseResults.length === 0 && (
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="h6"
                color="text.primary"
              >
                {summary}
              </Typography>
            )}
            <List>
              {parseResults.map((error, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    primary={`${error.code}: ${error.description}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {`${error.level} - Line ${error.start.row}:${error.start.col}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SummaryTab = ({ descs, onChange }) => {
  const [descriptions, setDescriptions] = useState([]);
  const [aiSummary, setAiSummary] = useState("");
  const [aiDescription, setAiDescription] = useState("");
  const theme = useTheme();

  useEffect(() => {
    setDescriptions(descs);
  }, []);

  const handleDescriptionChange = (index, event) => {
    const newDesc = [...descriptions];
    newDesc[index] = {
      lang: "UNKNOWN",
      summary: "",
      description: event.target.value,
    };
    setDescriptions(newDesc);
    onChange(descriptions);
  };

  const handleAddDescription = () => {
    setDescriptions([
      ...descriptions,
      { lang: "UNKNOWN", summary: "", description: "" },
    ]);
    onChange(descriptions);
  };

  const handleAISummarize = async () => {
    // TODO
  };

  return (
    <Box sx={{ flexGrow: 1, overflowY: "auto", pt: 1 }}>
      <Grid container spacing={2} columns={{ xs: 12 }}>
        <Grid item xs={6}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {descriptions.map((desc, index) => (
                <TextField
                  key={index}
                  value={desc.description}
                  multiline
                  onChange={(event) => handleDescriptionChange(index, event)}
                  variant="outlined"
                  sx={{ backgroundColor: theme.palette.background.input }}
                />
              ))}
              <Button variant="contained" onClick={handleAddDescription}>
                Add
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleAISummarize}>
                AI Summarize
              </Button>
            </Grid>
            <Grid item xs>
              <TextField value={aiSummary} multiline variant="outlined" />
            </Grid>
            <Grid item xs>
              <TextField value={aiDescription} multiline variant="outlined" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ExampleTab = ({ onApply }) => {
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const [selectedExample, setSelectedExample] = useState("");
  const [examples, setExamples] = useState([]);
  const { apiCall } = useApiCall();
  const theme = useTheme();

  useEffect(() => {
    loadExamples();
  }, []);

  const loadExamples = async () => {
    const examples = await listPremadeExamples(apiCall);
    setExamples(examples || []);
  };

  const handleSelectExample = (event) => {
    setSelectedExample(event.target.value);
  };

  const handleApplyExample = async () => {
    const example = await getPremadeExample(apiCall, selectedExample);
    onApply(example);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, margin: 2 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Select Example</InputLabel>
          <Select
            label="Select Example"
            value={selectedExample}
            onChange={handleSelectExample}
            sx={{ backgroundColor: theme.palette.background.input }}
          >
            {examples.map((example, index) => (
              <MenuItem key={example} value={example}>
                {example}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleApplyExample}>
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default PremadeScriptView;
