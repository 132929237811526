import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { DEV_AUTH_CONFIG } from "../aws-config";
import { useRecoilState } from "recoil";
import { originalPathState } from "../global-state";

const UserLogin = () => {
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  useEffect(() => {
    Amplify.configure(DEV_AUTH_CONFIG);
    handleLogin();
  }, []);

  const handleLogin = async () => {
    try {
      const oauthUrl = `https://${
        DEV_AUTH_CONFIG.Auth.oauth.domain
      }/login?redirect_uri=${
        DEV_AUTH_CONFIG.Auth.oauth.redirectSignIn
      }&response_type=code&client_id=${
        DEV_AUTH_CONFIG.Auth.userPoolWebClientId
      }&state=${encodeURIComponent(originalPath)}&scope=openid+email`;
      window.location.href = oauthUrl;
    } catch (error) {
      console.error("Error during sign-in", error);
    }
  };

  return <div></div>;
};

export default UserLogin;
