import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { DEV_AUTH_CONFIG } from "../aws-config";

const DeveloperLoginCallback = () => {
  const history = useHistory();

  useEffect(() => {
    const handleCallback = async () => {
      if (!history) {
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get("state");
      const path = state ? decodeURIComponent(state) : "/dashboard";
      try {
        Amplify.configure(DEV_AUTH_CONFIG);
        const session = await Auth.currentSession();
        const email = session.getIdToken().payload["email"];
        history.push(path);
      } catch (error) {
        console.error("Error during callback handling", error);
        history.push("/developer-login");
      }
    };

    handleCallback();
  }, [history]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1>Redirecting...</h1>
    </div>
  );
};

export default DeveloperLoginCallback;
