import React, { useEffect } from "react";

const OAuthCallback = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of queryParams.entries()) {
      paramsObject[key] = value;
    }
    const messageData = {
      event: "oauthCallback",
      data: paramsObject,
    };
    console.log("!!! post event");
    window.opener.postMessage(messageData, "*");
    window.close();

    return () => {};
  }, []);

  return (
    <div>
      <p>OAuth Callback Page</p>
      {/* You can add content here if needed */}
    </div>
  );
};

export default OAuthCallback;
