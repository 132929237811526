import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { developerIdTokenState, userInfoState } from "../global-state";
import { useWebSocket } from "../ws/websocket";

export const ScriptSpecData = forwardRef((props, ref) => {
  const [developerIdToken, setDeveloperIdToken] = useRecoilState(
    developerIdTokenState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  // key is block name
  const [blocks, setBlocks] = useState(new Map());
  // store the latest detailed input spec for the blockName, key is block name.
  // {blockName: {input: [], content: ''}}
  const [fields, setFields] = useState(new Map());
  const [integrationTypes, setIntegrationTypes] = useState([]);

  const { ws, wsReady, sendAndWaitWs } = useWebSocket();

  useEffect(() => {
    if (wsReady) {
      loadBaseSpec();
    }
  }, [wsReady, ws]);

  const loadBaseSpec = async () => {
    const payload = {
      type: "base",
    };
    const resp = await callWs(payload);
    if (resp.blocks) {
      for (const blockName in resp.blocks) {
        blocks.set(blockName, resp.blocks[blockName]);
      }
    }
    if (resp.integrationTypes) {
      setIntegrationTypes(resp.integrationTypes);
    }
  };

  const loadBlockSpec = async (name) => {
    console.log(name);
    const payload = {
      type: "block",
      name,
    };
    const resp = await callWs(payload);
    console.log(resp);
    if (resp.blocks) {
      for (const blockName in resp.blocks) {
        blocks.set(blockName, resp.blocks[blockName]);
      }
    }
  };

  const loadFieldSpec = async (name, fieldJson) => {
    console.log(name);
    console.log(fieldJson);

    const payload = {
      type: "field",
      name,
      fieldJson,
    };
    const resp = await callWs(payload);
    console.log(resp);
    if (resp.fields) {
      fields.set(name, { fields: resp.fields, sig: fieldJson });
    }
  };

  const callWs = async (payload) => {
    try {
      const response = await sendAndWaitWs("/spec", JSON.stringify(payload));
      if (response.error) {
        console.log("Server Error: ", response.error.error);
        return {};
      }
      return JSON.parse(response.payload);
    } catch (error) {
      console.error("Server Error: ", error);
      return {};
    }
  };

  const getIntegrationTypes = () => {
    return integrationTypes;
  };

  const getSpec = async (name) => {
    if (blocks.size === 0) {
      await loadBaseSpec();
      if (blocks.size === 0) {
        return null;
      }
    }
    if (blocks.has(name)) {
      return blocks.get(name);
    } else {
      await loadBlockSpec(name);
      return blocks.get(name);
    }
  };

  const getFieldSpec = async (name, integration, content) => {
    console.log(111);
    console.log(content);
    if (!name || !content || !integration) {
      return {};
    }
    const fieldData = content.reduce((acc, { key, value, values }) => {
      if (values && values.length > 0) {
        acc[key] = values;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    const fieldJson = JSON.stringify(fieldData);
    if (fields.has(name)) {
      if (fields.get(name).sig === fieldJson) {
        return fields.get(name).fields;
      }
    }
    console.log(fieldJson);
    console.log(name);
    await loadFieldSpec(name, fieldJson);
    return fields.get(name).fields;
  };

  useImperativeHandle(ref, () => ({
    getSpec,
    getIntegrationTypes,
    getFieldSpec,
  }));

  return <div></div>;
});

ScriptSpecData.displayName = "ScriptSpecData";
