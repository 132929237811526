export const checkEmailExists = async (apiCall, email) => {
  const request = { email };
  const response = await apiCall("/user/signup/check", "POST", request);
  return response;
};

export const checkExternalLogin = async (
  apiCall,
  email,
  providerName,
  providerUserId
) => {
  const request = { email, providerName, providerUserId };
  const response = await apiCall("/user/login/check", "POST", request);
  return response;
};
