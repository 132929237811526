import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { Box, Tab, Tabs, TextField, Button, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { originalPathState } from "../global-state";
import { useHistory } from "react-router-dom";
import { Card, IconButton, useMediaQuery } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useTheme } from "@mui/material/styles";
import { checkEmailExists } from "./user-login-service";
import useApiCall from "../common/api-call";

async function signIn(email, password) {
  try {
    const user = await Auth.signIn(email, password);
    console.log("Login successful:", user);
  } catch (error) {
    console.error("Error signing in:", error);
  }
}

const UserLogin = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showResetFields, setShowResetFields] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { apiCall } = useApiCall();
  const [countdown, setCountdown] = useState(0);
  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasMinLength: false,
    noLeadingTrailingSpace: false,
  });

  useEffect(() => {
    Amplify.configure(USER_AUTH_CONFIG);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const validatePassword = (pwd) => {
    setValidations({
      hasLowerCase: /[a-z]/.test(pwd),
      hasUpperCase: /[A-Z]/.test(pwd),
      hasNumber: /\d/.test(pwd),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<> ]/.test(pwd),
      hasMinLength: pwd.length >= 8,
      noLeadingTrailingSpace: pwd.trim().length === pwd.length,
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const allValid = Object.values(validations).every(Boolean);

  const startCountdown = () => {
    setCountdown(60);
    setIsCountdownActive(true);

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          setIsCountdownActive(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSignIn = async () => {
    try {
      if (!email) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }

      if (!email || !password) {
        return;
      }

      const user = await Auth.signIn(email, password);
      history.push(originalPath || "/");
    } catch (error) {
      setErrorMessage("Failed to sign in. Please check your credentials.");
    }
  };

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setShowResetFields(true);
      setErrorMessage("Password reset code sent to your email.");
    } catch (error) {
      setErrorMessage("Failed to send reset code. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, resetCode, newPassword);
      setErrorMessage("Password reset successful! You can now sign in.");
      setShowResetFields(false);
      setActiveTab(0); // Switch to SignIn tab after reset
    } catch (error) {
      setErrorMessage("Failed to reset password. Please check your code.");
    }
  };

  const handleGoogleSignIn = async () => {
    console.log("Initiating Google federated sign-in...");
    const response = await Auth.federatedSignIn({ provider: "Google" });
    console.log("Google sign-in successful, fetching user...");
  };

  const handleSignUp = async () => {
    try {
      if (!email) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }

      if (!email || !password) {
        return;
      }

      console.log("Checking if email exists:", email);
      const emailExists = await checkEmailExists(apiCall, email);
      console.log("Email exists result:", emailExists);

      if (emailExists) {
        console.log("Email already registered.");
        setErrorMessage(
          "The email address has been registered. Please try to log in or use another email address."
        );
        return;
      }
      setShowConfirm(true);
      console.log("Starting manual registration for email:", email);
      await Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });
      console.log("Sign up successful, verification code sent.");
      setErrorMessage(
        "Verification code sent to your email.Valid within five minutes"
      );
      startCountdown();
    } catch (error) {
      console.error("Error during sign up:", error);
      setErrorMessage("Error signing up. Please try again.");
    }
  };

  const handleConfirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setErrorMessage("Sign up successful! You can now sign in.");
      setShowConfirm(false);
      setActiveTab(0); // Switch to SignIn tab after successful verification
    } catch (error) {
      setErrorMessage("Invalid verification code. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: isMobile
          ? theme.palette.background.white
          : theme.palette.background.log1,
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: { xs: "100%", sm: "90%", md: "800px", lg: "1000px" },
          height: isMobile ? "auto" : "600px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          boxShadow: isMobile ? 0 : 3,
          borderRadius: "12px",
          bgcolor: isMobile ? "transparent" : "background.white",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: `${theme.palette.background.log2}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                color: "text.white",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Welcome <br />
              Back
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            height: "100%",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            bgcolor: isMobile ? "transparent" : "background.white",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{ pt: password && activeTab === 1 && !isMobile ? 15 : 4 }}
            align="center"
          >
            Welcome
          </Typography>

          {errorMessage && (
            <Typography color="error" align="center">
              {errorMessage}
            </Typography>
          )}

          <Box sx={{ mt: 2 }}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailError(false)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  "& input": {
                    p: 1.5,
                  },
                  ...(emailError && {
                    borderColor: "red",
                    "&:hover fieldset": {
                      borderColor: "red",
                    },
                  }),
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1",
                },
              }}
              error={emailError}
            />
            {activeTab == 0 && (
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setPasswordError(false)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& input": {
                      p: 1.5,
                    },
                    ...(emailError && {
                      borderColor: "red",
                      "&:hover fieldset": {
                        borderColor: "red",
                      },
                    }),
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1",
                  },
                }}
                error={passwordError}
              />
            )}

            {activeTab == 1 && (
              <>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                  onFocus={() => setPasswordError(false)}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      "& input": {
                        p: 1.5,
                      },
                      ...(emailError && {
                        borderColor: "red",
                        "&:hover fieldset": {
                          borderColor: "red",
                        },
                      }),
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1",
                    },
                  }}
                  error={passwordError}
                />
                {password && (
                  <ul>
                    <li
                      style={{
                        color: validations.hasLowerCase ? "green" : "red",
                      }}
                    >
                      {validations.hasLowerCase ? "✔ " : "× "}Password must
                      contain a lower case letter
                    </li>
                    <li
                      style={{
                        color: validations.hasUpperCase ? "green" : "red",
                      }}
                    >
                      {validations.hasUpperCase ? "✔ " : "× "}Password must
                      contain an upper case letter
                    </li>
                    <li
                      style={{ color: validations.hasNumber ? "green" : "red" }}
                    >
                      {validations.hasNumber ? "✔ " : "× "}Password must contain
                      a number
                    </li>
                    <li
                      style={{
                        color: validations.hasMinLength ? "green" : "red",
                      }}
                    >
                      {validations.hasMinLength ? "✔ " : "× "}Password must
                      contain at least 8 characters
                    </li>
                    <li
                      style={{
                        color: validations.hasSpecialChar ? "green" : "red",
                      }}
                    >
                      {validations.hasSpecialChar ? "✔ " : "× "}Password must
                      contain a special character or a space
                    </li>
                    <li
                      style={{
                        color: validations.noLeadingTrailingSpace
                          ? "green"
                          : "red",
                      }}
                    >
                      {validations.noLeadingTrailingSpace ? "✔ " : "× "}Password
                      must not contain a leading or trailing space
                    </li>
                  </ul>
                )}
              </>
            )}
          </Box>
          <Box sx={{ textAlign: "left" }}>
            {activeTab === 0 && (
              <Button
                variant="text"
                sx={{ color: "text.l1", textTransform: "none" }}
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Button>
            )}
          </Box>
          {showResetFields && activeTab == 0 && (
            <>
              <TextField
                label="Reset Code"
                fullWidth
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& input": {
                      p: 1.5,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1",
                  },
                }}
              />
              <TextField
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& input": {
                      p: 1.5,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1",
                  },
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 1 }}
                onClick={() => setShowResetFields(false)}
              >
                Cancle Reset Password
              </Button>
            </>
          )}

          <Box sx={{ textAlign: "center" }}>
            {activeTab == 0 && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSignIn();
                  }}
                  fullWidth
                  sx={{
                    borderRadius: "50px",

                    mt: 2,
                    bgcolor: activeTab === 0 ? "primary.main" : "transparent",
                    color: activeTab === 0 ? "white" : "primary.main",
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  Sign In
                </Button>
              </>
            )}

            {activeTab == 1 && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSignUp();
                  }}
                  disabled={isCountdownActive}
                  fullWidth
                  sx={{
                    borderRadius: "50px",
                    mt: 3,
                    bgcolor: activeTab === 1 ? "primary.main" : "transparent",
                    color: activeTab === 1 ? "white" : "primary.main",
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {isCountdownActive
                    ? `Resend in ${countdown}s`
                    : "Send Verification Code"}
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              startIcon={<GoogleIcon />}
              fullWidth
              sx={{
                mt: 2,
                borderRadius: "50px",
                textTransform: "none",
              }}
              onClick={handleGoogleSignIn}
            >
              {activeTab === 0 ? "Sign in with Google" : "Sign up with Google"}
            </Button>
          </Box>
          {activeTab === 0 && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Already have an account? &nbsp;{" "}
                <span
                  style={{ color: theme.palette.text.l1, cursor: "pointer" }}
                  onClick={() => setActiveTab(1)}
                >
                  Sign Up
                </span>
              </Typography>
            </Box>
          )}

          {activeTab === 1 && (
            <Box sx={{ textAlign: "center" }}>
              {showConfirm && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      jusifyContenx: "flex-start",
                    }}
                  >
                    <Button variant="outlined" sx={{ mr: 1 }}>
                      Verification Code :
                    </Button>
                    <TextField
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      sx={{
                        flex: 1,
                        mt: 2.5,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          "& input": {
                            p: 0.8,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      borderRadius: "50px",
                      mt: 2,
                      textTransform: "none",
                      color: "text.white",
                    }}
                    onClick={handleConfirmSignUp}
                  >
                    Confirm Sign Up
                  </Button>
                </>
              )}
              <Typography variant="body2" sx={{ mt: 2 }}>
                Need an account?&nbsp;{" "}
                <span
                  style={{ color: theme.palette.text.l1, cursor: "pointer" }}
                  onClick={() => setActiveTab(0)}
                >
                  Sign In
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default UserLogin;
