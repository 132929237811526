import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import MicIcon from "@mui/icons-material/Mic";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled, useTheme } from "@mui/material/styles";

const InstructionInput = ({ placeholder, onSend }) => {
  const [instruction, setInstruction] = useState("");
  const [speeches, setSpeeches] = useState([""]);
  const [speech, setSpeech] = useState("");
  const [open, setOpen] = useState(false);
  const recognitionRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current.onend = null;
      }
    };
  }, []);

  useEffect(() => {
    if (recognitionRef.current) {
      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        speeches[speeches.length - 1] = transcript;
        setSpeech(transcript);
      };

      recognitionRef.current.onend = () => {
        speeches.push("");
        recognitionRef.current.start();
      };
    }
  }, [speeches]);

  const handleSend = () => {
    onSend(instruction);
    setInstruction("");
  };

  const setupRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition && !recognitionRef.current) {
      const recognition = new SpeechRecognition();
      recognition.interimResults = true;
      recognition.lang = "en-US";
      recognitionRef.current = recognition;
    }
  };

  const handleMicClick = () => {
    setOpen(true);
    setSpeech("");
    setSpeeches([""]);
    setupRecognition();
    if (recognitionRef.current) {
      recognitionRef.current.start();
    }
  };

  const handleClose = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current.onend = null;
    }
    const ins = speeches.join(" ");
    setInstruction(ins);
    setOpen(false);
    onSend(ins);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.ctrlKey) {
      event.preventDefault();
      handleSend();
    } else if (event.key === "Enter" && event.ctrlKey) {
      setInstruction(instruction + "\n");
    }
  };

  return (
    <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
      <TextField
        sx={{ flexGrow: 1, mr: 1 }}
        variant="outlined"
        placeholder={placeholder}
        value={instruction}
        onChange={(event) => {
          setInstruction(event.target.value);
        }}
        onKeyDown={handleKeyDown}
        InputProps={{
          sx: { backgroundColor: theme.palette.background.input },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleMicClick}>
                <MicIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomIconButton onClick={handleSend}>
        <ArrowUpwardIcon />
      </CustomIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="voice-input"
        maxWidth="sm"
      >
        <div style={{ padding: 20, minWidth: 300 }}>
          <p>Listening... Tap outside to finish.</p>
          <p>{speech}</p>
        </div>
      </Dialog>
    </Box>
  );
};

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default InstructionInput;
