import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { prepareWidget } from "./widget-util";
import TimestampInput from "../../input/timestamp-input";

const DateTime1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState();

  useEffect(() => {
    setV0(prepareWidget(widget));
    return () => {};
  }, [widget]);

  const handleValueUpdate = (data) => {
    const updatedWidget = { ...v0, data: { ...v0.data, value: data } };
    onValueUpdate(updatedWidget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
        padding: "2px",
      }}
    >
      {v0?.data && (
        <TimestampInput valueSpec={v0.data} onChange={handleValueUpdate} />
      )}
    </Box>
  );
};

export default DateTime1Widget;
