import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const DeleteAutomationDialog = ({ open, onClose, onDelete }) => {
  const theme = useTheme();

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ p: theme.spacing(3) }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Confirm Delete
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: theme.spacing(3), pt: theme.spacing(1) }}>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.secondary }}
        >
          Are you sure you want to delete this automation? This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ p: theme.spacing(2), justifyContent: "space-between" }}
      >
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.text.primary,
            "&:hover": { backgroundColor: theme.palette.grey[400] },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          sx={{
            backgroundColor: theme.palette.background.l3,
            color: theme.palette.common.white,
            "&:hover": { backgroundColor: theme.palette.background.l3 },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAutomationDialog;
