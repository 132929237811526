import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useRecoilState } from "recoil";
import { developerIdTokenState } from "../global-state";
import LoadingSpinner from "../common/loading";
import { listPremades, searchPremades } from "./premade-service";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { ButtonBase, InputBase, Paper } from "@mui/material";
import useApiCall from "../common/api-call";
import PremadeFilter from "./premade-filter";
import { SearchIcon } from "lucide-react";

const PremadeGalleryView = ({ showFilter = false, setShowFilter }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [premades, setPremades] = useState([]);
  const [devPremades, setDevPremades] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const prevSearchTextRef = useRef("");
  const { apiCall } = useApiCall();
  const [sortBy, setSortBy] = useState("alphabet");
  const [filterText, setFilterText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isShowFilter, setIsShowFilter] = useState(showFilter);

  useEffect(() => {
    setIsShowFilter(showFilter);
  }, [showFilter]);

  useEffect(() => {
    handleListPremades();
  }, []);

  const sortedPremades = premades
    .filter((p) => p.summary.toLowerCase().includes(filterText.toLowerCase()))
    .sort((a, b) => {
      if (sortBy === "alphabet") {
        const aName = a.summary.toLowerCase();
        const bName = b.summary.toLowerCase();

        const aIsLetter = /^[a-zA-Z]/.test(aName);
        const bIsLetter = /^[a-zA-Z]/.test(bName);
        if (aIsLetter && !bIsLetter) return -1;
        if (!aIsLetter && bIsLetter) return 1;
        return aName.localeCompare(bName); //sort alphabetically
      }
    });

  const handleListPremades = async () => {
    setLoading(true);
    const resp = await listPremades(apiCall);

    if (resp !== null) {
      setPremades(resp["premades"]);
      setDevPremades(resp["devPremades"]);
      setLoading(false);
    }
  };

  const handleEdit = (data) => {
    history.push(`/premade/script?id=${data.id}&version=${data.version}`);
  };

  const handleAdopt = (data) => {
    history.push(
      `/automation/overview?premadeId=${data.id}&premadeVersion=${data.version}`
    );
  };

  const handleSearch = async () => {
    setLoading(true);
    const prevSearchText = prevSearchTextRef.current;
    if (searchText !== prevSearchText) {
      prevSearchTextRef.current = searchText;
      if (searchText) {
        // pass
      } else {
        handleListPremades();
      }
    } else {
      setLoading(false);
      return;
    }

    const resp = await searchPremades(apiCall, searchText);
    if (resp !== null) {
      setPremades(resp["premades"]);
      setDevPremades([]);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, overflowY: "auto", pb: 12 }}>
      {devPremades.length > 0 && (
        <Box sx={{ flexGrow: 1 }}>
          <DraftPremadesBlock
            premades={devPremades}
            onAdopt={handleAdopt}
            onScript={handleEdit}
          />
          <Divider />
        </Box>
      )}
      <Box sx={{ px: 2, py: 1 }}>
        <Paper
          elevation={0}
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 1,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search premades..."
            name="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
          />
          <IconButton sx={{ p: "10px" }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isShowFilter && (
            <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
              <PremadeFilter
                sortBy={sortBy}
                setSortBy={setSortBy}
                setFilterText={setFilterText}
                setShowFilter={setShowFilter}
              />
            </Box>
          )}
          <AllPremadesBlock
            premades={sortedPremades}
            onAdopt={handleAdopt}
            onScript={handleEdit}
          />
        </>
      )}
    </Box>
  );
};

const DraftPremadesBlock = ({ premades, onAdopt, onScript }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ flexGrow: 1, p: 2, backgroundColor: theme.palette.background.l3 }}
    >
      <Typography variant="h4" gutterBottom>
        Dev Drafts
      </Typography>
      <PremadesList
        title={"Recommended"}
        premades={premades}
        onAdopt={onAdopt}
        onScript={onScript}
      />
    </Box>
  );
};

const AllPremadesBlock = ({ premades, onAdopt, onScript }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <PremadesList
        title={"Recommended"}
        premades={premades}
        onAdopt={onAdopt}
        onScript={onScript}
      />
    </Box>
  );
};

const PremadesList = ({ premades, onAdopt, onScript }) => {
  const [developerIdToken] = useRecoilState(developerIdTokenState);

  return (
    <Grid
      container
      spacing={{ xs: 1, md: 2 }}
      columns={{ xs: 2, sm: 4, lg: 12 }}
    >
      {premades.map((premade, index) => (
        <Grid item xs={2} sm={2} lg={4} key={index}>
          <Card elevation={0}>
            <ButtonBase
              component="div" // Use div to prevent nesting button elements
              onClick={() => onAdopt(premade)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "8px",
                textAlign: "left",
              }}
            >
              <Box display="flex" alignItems="center" flexGrow={1}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "4.5em",
                    marginBottom: 0,
                  }}
                >
                  {premade.summary}
                </Typography>
              </Box>
              {developerIdToken && (
                <Box sx={{ marginLeft: "auto", paddingLeft: "16px" }}>
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={(event) => {
                      event.stopPropagation();
                      onScript(premade);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </ButtonBase>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PremadeGalleryView;
