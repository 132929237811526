import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import { useWebSocket } from "../ws/websocket";
import LoadingSpinner from "../common/loading";
import { getDebugInfo } from "./automation-service";
import { useToast } from "../common/toast";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useApiCall from "../common/api-call";

const AutomationDebugView = () => {
  const path = useLocation();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const history = useHistory();
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const [automationId, setAutomationId] = useState(null);
  const [debugThreads, setDebugThreads] = useState([]);
  const [parseResults, setParseResults] = useState([]);
  const { apiCall } = useApiCall();
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setAutomationId(params["id"]);
    handleGetDebugInfo(params["id"]);
    return () => {};
  }, [path, ws]);

  const handleGetDebugInfo = async (automationId) => {
    const resp = await getDebugInfo(
      apiCall,
      userInfo.activeScope.id,
      automationId
    );
    setDebugThreads(resp.threads || []);
    setParseResults(resp.parseResults || []);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: {
          xs: "calc(100vh - 56px)",
          sm: "calc(100vh - 64px)",
        },
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box m={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Parse Results</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ bgcolor: "background.paper" }}>
                  {parseResults.length === 0 && (
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="h6"
                      color="text.primary"
                    >
                      Build Success
                    </Typography>
                  )}
                  <List>
                    {parseResults.map((error, index) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemText
                          primary={`${error.code}: ${error.description}`}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {`${error.level} - Line ${error.start.row}:${error.start.col}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </AccordionDetails>
            </Accordion>
            {debugThreads.map((debugThread, index) => (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6">
                      Thread {debugThread.threadId} Plan
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                      {debugThread.plan}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6">
                      Thread {debugThread.threadId} Memory
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                      {debugThread.memory}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AutomationDebugView;
