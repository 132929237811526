import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const TextInput = ({ valueSpec, onChange }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    console.log(valueSpec);
    setValue(valueSpec.value);
    return () => {};
  }, [valueSpec]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      {valueSpec.name && (
        <Grid item xs>
          <Typography variant="h6">{valueSpec.name}</Typography>
        </Grid>
      )}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          minHeight: 0, // Important for proper flex behavior
        }}
      >
        <TextField
          label={valueSpec.name || ""}
          value={value}
          onChange={handleInputChange}
          fullWidth
          multiline
          size="small"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            "& .MuiInputBase-root": {
              height: "100%",
              padding: "4px 8px",
              display: "flex",
            },
            "& .MuiInputBase-input": {
              padding: 0,
              lineHeight: 1.2,
              height: "100% !important",
              overflow: "auto !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 1,
            },
            "& .MuiFormLabel-root": {
              lineHeight: "1rem",
              transform: "translate(8px, 4px) scale(0.75)",
              "&.MuiFormLabel-filled, &.Mui-focused": {
                transform: "translate(8px, -9px) scale(0.75)",
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TextInput;
