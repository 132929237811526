export const fixedSelectCheck = (value, valueSpec) => {
  if (!valueSpec || !valueSpec.enums) {
    return false;
  }
  if (!value) {
    return false;
  }
  if (valueSpec.enums.indexOf(value) === -1) {
    return false;
  }
  if (valueSpec.valueCheck === false && valueSpec.value === value) {
    return false;
  }
  return true;
};
