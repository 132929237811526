import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import UpsertWidgetDialog from "./upsert-widget-dialog";
import { useWebSocket } from "../ws/websocket";
import { useEventEmitter } from "../ws/event-context";
import {
  deleteCard,
  removeCanvasCard,
  updateCanvasCards,
  updateCanvasLayout,
  upsertWidget,
} from "./dashboard-service";
import { useToast } from "../common/toast";
import { useBackdrop } from "../common/backdrop";
import AddWidgetCard from "./add-widget-card";
import useApiCall from "../common/api-call";
import { Paper, useMediaQuery } from "@mui/material";
import DashboardCard from "./dashboard-card";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditCardDialog from "./edit-card-dialog";
import DeleteCardDialog from "./delete-card-dialog";
import { useTheme } from "@mui/material/styles";

const ResponsiveGridLayout = WidthProvider(Responsive);

const generateLayouts = (cardData, cols) => {
  let nextY = 0;
  let nextX = 0;

  return cardData.map((card) => {
    let { w, h, x, y } = card.layout;

    if (x === null || y === null || x === undefined || y === undefined) {
      // Auto-calculate x and y for missing ones
      if (nextX + w > cols) {
        nextX = 0;
        nextY += h; // Move to next row if space is not enough
      }
      x = nextX;
      y = nextY;
      nextX += w;
    } else {
      // If x and y are provided, move nextX and nextY accordingly
      nextX = Math.max(nextX, x + w);
      nextY = Math.max(nextY, y);
    }

    return { i: card.id, x, y, w, h };
  });
};

const DashboardView = ({ edit = false, drag = false }) => {
  const isXxs = useMediaQuery("(max-width:480px)");
  const isXs = useMediaQuery("(min-width:480px) and (max-width:768px)");
  const isSm = useMediaQuery("(min-width:768px) and (max-width:996px)");
  const isMd = useMediaQuery("(min-width:996px) and (max-width:1200px)");
  const isLg = useMediaQuery("(min-width:1200px)");

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { ws, sendAndWaitWs, sendAndForgetWs } = useWebSocket();
  const { subscribe } = useEventEmitter();
  const [currentWidget, setCurrentWidget] = useState(null);
  const [currentCard, setCurrentCard] = useState(null);
  const { successToast, errorToast } = useToast();
  const location = useLocation();
  const [layouts, setLayouts] = useState(null);

  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [widgetMap, setWidgetMap] = useState(new Map());
  const [cardMap, setCardMap] = useState(new Map());
  const [widget2Card, setWidget2Card] = useState(new Map());
  const [cardList, setCardList] = useState([]);
  const revisionRef = useRef(-1);
  const curCanvasRef = useRef("");
  const skipEvent = useRef(false);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const [openWidgetDialog, setOpenWidgetDialog] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [widgetNames, setWidgetNames] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const { apiCall } = useApiCall();

  useEffect(() => {
    if (!dashboardLoading) {
      const timer = setTimeout(() => {
        setIsLayoutReady(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [dashboardLoading]);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "_closed_") {
        openBackdrop("Connection lost. Please Refresh.");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      // clearInterval(pollInterval);
      closeBackdrop();
      loadDashboard();
    }
  }, [ws, ws?.readyState]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const canvasId = searchParams.get("canvas");
    if (canvasId) {
      curCanvasRef.current = canvasId;
    }
    console.log(canvasId);
    const { unsubscribe } = subscribe((target, data) => {
      if (skipEvent.current) {
        return;
      }
      if (target === "/ui/widget/update") {
        const payload = JSON.parse(data.payload);
        for (let change of payload.changes) {
          if (change.type === "UPSERT") {
            handleWidgetUpdateEvent(change.widget);
          }
        }
      } else if (target === "/ui/dashboard/update") {
        const change = JSON.parse(data.payload);
        if (
          change.type === "UPDATE_CANVAS" &&
          change.canvasId === curCanvasRef.current
        ) {
          handleCanvasUpdateEvent(change.revision);
        }
      } else if (target === "/ui/card/update") {
        const change = JSON.parse(data.payload);
        // TODO
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (layoutChanged && !drag) {
      console.log(cardList);
      handleUpdateLayout();
      setLayoutChanged(false);
    }
  }, [drag]);

  const handleCanvasUpdateEvent = (lr) => {
    // TODO
    console.log(lr);
    if (revisionRef.current === -1) {
      setTimeout(() => {
        handleCanvasUpdateEvent(lr);
      }, 500);
    } else {
      if (revisionRef.current !== lr) {
        setTimeout(() => {
          if (revisionRef.current !== lr) {
            openBackdrop("Canvas updated, Refresh");
          }
        }, 500);
      }
    }
  };

  const handleWidgetUpdateEvent = (widget) => {
    if (!widget2Card.get(widget.id)) {
      // not related widget, ignore
    } else {
      widgetMap.set(widget.id, widget);
      for (const cardId of widget2Card.get(widget.id)) {
        const card = cardMap.get(cardId);
        if (card) {
          const newWidgets = card.widgets.map((w) =>
            w.id === widget.id ? { ...w, ...widget } : w
          );
          const newCard = { ...card, widgets: newWidgets };
          cardMap.set(newCard.id, newCard);
        }
      }
      setCardList(Array.from(cardMap.values()));
      setWidgetNames(Array.from(widgetMap.values()).map((w) => w.name));
    }
  };

  const currentLayout = () => {
    if (isXs) return "xs";
    if (isSm) return "sm";
    if (isMd) return "md";
    if (isLg) return "lg";
    return "xs";
  };

  const getCols = () => {
    if (isXxs) return 4;
    if (isXs) return 4;
    if (isSm) return 6;
    if (isMd) return 10;
    if (isLg) return 12;
    return 4;
  };

  const prepareCards = (cardList, widgetList) => {
    const layoutSize = currentLayout();

    widgetMap.clear();
    cardMap.clear();
    for (let i = 0; i < widgetList.length; i++) {
      widgetMap.set(widgetList[i].id, widgetList[i]);
    }
    setWidgetNames(Array.from(widgetMap.values()).map((w) => w.name));

    const cards = cardList
      .map((cd) => {
        const layout = cd.layouts[layoutSize] || {};
        const detailedWidgets = cd.widgets
          .filter((widget) => widgetMap.has(widget.id))
          .map((widget) => {
            const fw = widgetMap.get(widget.id);
            const updated = { ...widget, ...fw };
            return updated;
          })
          .filter((widget) => widget !== undefined && widget !== null);
        detailedWidgets.forEach((widget) => {
          if (!widget2Card.has(widget.id)) {
            widget2Card.set(widget.id, []);
          }
          widget2Card.get(widget.id).push(cd.id);
        });
        return detailedWidgets.length > 0
          ? {
              id: cd.id,
              name: cd.name,
              pattern: cd.pattern,
              skin: cd.skin,
              layout: layout,
              widgets: detailedWidgets,
            }
          : null;
      })
      .filter((group) => group !== null);

    for (let i = 0; i < cards.length; i++) {
      cardMap.set(cards[i].id, cards[i]);
    }

    setCardList(cards);
    const lo = generateLayouts(cards, getCols());
    setLayouts(lo);
  };

  const loadDashboard = async () => {
    try {
      setDashboardLoading(true);
      const response = await sendAndWaitWs(
        "/ui/dashboard/read",
        JSON.stringify({})
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const jsonData = JSON.parse(response.payload);
      console.log(jsonData);
      history.replace(`/dashboard?canvas=${jsonData.currentCanvas}`);
      curCanvasRef.current = jsonData.currentCanvas;
      revisionRef.current = jsonData.meta.revision;
      prepareCards(
        jsonData.meta.canvas.find((x) => x.id === jsonData.currentCanvas).cards,
        jsonData.widgets
      );
      setDashboardLoading(false);
      closeBackdrop();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNewWidgetConfig = () => {
    setCurrentWidget(null);
    setOpenWidgetDialog(true);
  };

  const handleCloseWidgetDialog = () => {
    setOpenWidgetDialog(false);
  };

  const handleCloseCardDialog = () => {
    setOpenCardDialog(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleUpsertWidget = async (uiWidget) => {
    const isNew = uiWidget.id === null || uiWidget.id === undefined;
    skipEvent.current = true;
    const success = await upsertWidget(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      uiWidget
    );
    if (success) {
      if (isNew) {
        successToast("Add widget success");
      } else {
        successToast("Save widget success");
      }
    } else {
      if (isNew) {
        errorToast("Add widget error!");
      } else {
        errorToast("Save widget error!");
      }
    }
    loadDashboard();
  };

  const handleUpdateCard = async (card) => {
    const newCard = {
      id: card.id,
      name: card.name,
      widgetIds: card.widgets.map((w) => w.id),
    };
    skipEvent.current = true;
    const success = await updateCanvasCards(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      [newCard]
    );
    if (success) {
      successToast("Save widget success");
      loadDashboard();
      setOpenCardDialog(false);
    } else {
      errorToast("Save widget error!");
    }
  };

  const handleRemoveCard = async (cardId) => {
    skipEvent.current = true;
    const resp = await removeCanvasCard(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      cardId
    );
    if (resp) {
      successToast("Removed");
      if (resp.canDelete) {
        // TODO: ask for delete when have multi canvas
        // handleOpenDeleteCard(resp.card);
        await handleDeleteCard(cardId);
      }
      loadDashboard();
      setOpenCardDialog(false);
    } else {
      errorToast("Remove card error!");
    }
  };

  const handleDeleteCard = async (cardId) => {
    const success = await deleteCard(apiCall, userInfo.activeScope.id, cardId);
    if (success) {
      successToast("Deleted");
      setOpenDeleteDialog(false);
    } else {
      errorToast("Remove card error!");
    }
  };

  const handleUpdateLayout = async () => {
    console.log(cardList);
    const cl = currentLayout();
    const layouts = cardList.map((c) => {
      return { id: c.id, layout: c.layout, layoutSize: cl };
    });
    skipEvent.current = true;
    const success = await updateCanvasLayout(
      apiCall,
      userInfo.activeScope.id,
      curCanvasRef.current,
      layouts
    );
    if (success) {
      successToast("Save layout success");
      loadDashboard();
    } else {
      errorToast("Save layout error!");
    }
  };

  const handleEditWidget = async (widgetId) => {
    console.log(widgetId);
    const widget = widgetMap.get(widgetId);
    if (widget) {
      setCurrentWidget(widget);
      setOpenWidgetDialog(true);
    }
  };

  const handleEditCard = async (card) => {
    setCurrentCard(card);
    setOpenCardDialog(true);
  };

  const handleOpenDeleteCard = async (card) => {
    setCurrentCard(card);
    setOpenDeleteDialog(true);
  };

  const handleLayoutChange = (currentLayout) => {
    const updatedCardList = cardList.map((card) => {
      const layoutItem = currentLayout.find((layout) => layout.i === card.id);
      if (layoutItem) {
        card.layout = {
          ...card.layout,
          x: layoutItem.x,
          y: layoutItem.y,
          w: layoutItem.w,
          h: layoutItem.h,
        };
        return card;
      }
      return card;
    });
    setCardList(updatedCardList);
    setLayouts(currentLayout);
    setLayoutChanged(true);
  };

  return (
    <>
      {dashboardLoading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            minHeight: "100vh",
            mb: 4,
            visibility: isLayoutReady ? "visible" : "hidden",
            opacity: isLayoutReady ? 1 : 0,
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          {edit && <AddWidgetCard onClick={handleNewWidgetConfig} />}
          <ResponsiveGridLayout
            className="layout"
            layouts={{
              lg: layouts,
              md: layouts,
              sm: layouts,
              xs: layouts,
              xxs: layouts,
            }}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
            rowHeight={20}
            onLayoutChange={handleLayoutChange}
            compactType="vertical"
            preventCollision={false}
            isDraggable={drag}
            isResizable={drag}
            margin={[10, 10]}
            useCSSTransforms={isLayoutReady}
          >
            {cardList.map((card) => (
              <div key={card.id}>
                {edit && (
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditCard(card);
                    }}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "40px",
                      height: "100%",
                      zIndex: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: theme.palette.background.disabled,
                      cursor: "pointer",
                    }}
                  >
                    <IconButton aria-label="delete">
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
                <Paper
                  elevation={0}
                  sx={{
                    height: "100%",
                    width: "100%",
                    padding: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    bgcolor: card.color || "white",
                    overflow: "hidden",
                    boxSizing: "border-box",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    opacity: drag || edit ? 0.8 : 1,
                    filter: drag || edit ? "grayscale(100%)" : "none",
                    pointerEvents: drag || edit ? "none" : "auto",
                  }}
                >
                  <DashboardCard card={card}></DashboardCard>
                </Paper>
              </div>
            ))}
          </ResponsiveGridLayout>
          <UpsertWidgetDialog
            open={openWidgetDialog}
            current={currentWidget}
            existingNames={widgetNames}
            onClose={handleCloseWidgetDialog}
            onSave={handleUpsertWidget}
          />
          <DeleteCardDialog
            open={openDeleteDialog}
            card={currentCard}
            onClose={handleCloseDeleteDialog}
            onDelete={handleDeleteCard}
          />
          <EditCardDialog
            open={openCardDialog}
            card={currentCard}
            onEditWidget={handleEditWidget}
            onClose={handleCloseCardDialog}
            onSave={handleUpdateCard}
            onRemove={handleRemoveCard}
          />
        </Box>
      )}
    </>
  );
};
export default DashboardView;
