import React, { createContext, useContext, useRef } from "react";

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const listenersRef = useRef([]);

  const subscribe = (listener) => {
    listenersRef.current = [...listenersRef.current, listener];
    const unsubscribe = () => {
      listenersRef.current = listenersRef.current.filter((l) => l !== listener);
    };
    return { unsubscribe, listener };
  };

  const emit = (eventName, eventData) => {
    listenersRef.current.forEach((listener) => {
      listener(eventName, eventData);
    });
  };

  return (
    <EventContext.Provider value={{ subscribe, emit }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventEmitter = () => useContext(EventContext);
