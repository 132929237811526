import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

const Button1Widget = ({ widget, onValueUpdate }) => {
  const [v0, setV0] = useState({});

  useEffect(() => {
    setV0(widget);
    return () => {};
  }, [widget]);

  const handleValueUpdate = () => {
    onValueUpdate(v0.id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        height: "100%",
        width: "100%",
        padding: "2px",
      }}
    >
      {v0?.button && (
        <Button
          variant="contained"
          onClick={() => handleValueUpdate()}
          fullWidth
          sx={{
            height: "100%", // Full height for Button
          }}
        >
          {v0.button.label}
        </Button>
      )}
    </Box>
  );
};

export default Button1Widget;
