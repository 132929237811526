import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import { customFormat } from "../common/custom-format";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const NotificationDialogList = ({
  notifications,
  dialogs,
  onClose,
  onNotificationClick,
  onDialogClick,
  onDialogTimeout,
  open,
  onClearNotifications,
}) => {
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const now = dayjs();

    let dd = null;
    for (let i = 0; i < dialogs.length; i++) {
      const d = dialogs[i];
      if (dayjs(d.createdAt).add(d.timeout, "millisecond").isBefore(now)) {
        dd = d;
        break;
      }
    }
    if (dd) {
      onDialogTimeout(dd);
    }
  }, [dialogs]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  const calculateRemainingTime = (timeout) => {
    const now = currentTime;
    const endTime = dayjs(timeout);
    return endTime.diff(now, "second");
  };

  // Combine and sort notifications and dialogs by timestamp
  const combinedItems = [...notifications, ...dialogs].sort((a, b) => {
    const t1 = a.dialogId ? a.createdAt : a.timestamp;
    const t2 = b.dialogId ? b.createdAt : b.timestamp;
    return t2 - t1;
  });

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box role="presentation" p={2} width={400}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            Notifications
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClearNotifications}
          sx={{ mb: 2 }}
        >
          Clear All
        </Button>
        <List>
          {combinedItems.map((item, index) => {
            // dialog has timeout
            const remainingTime = item.timeout
              ? calculateRemainingTime(item.createdAt + item.timeout)
              : null;
            return (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() =>
                    item.dialogId
                      ? onDialogClick(item)
                      : onNotificationClick(item)
                  }
                  sx={{
                    backgroundColor: item.dialogId
                      ? theme.palette.background.dialog
                      : theme.palette.background.notification,
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {customFormat(dayjs(item.timestamp), currentTime)}
                        </Typography>
                        <Typography variant="h6">
                          {item.title || "Action Required"}
                        </Typography>
                      </>
                    }
                  />
                  {remainingTime !== null && (
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        value={(remainingTime / (item.timeout / 1000)) * 100}
                        size={40}
                        thickness={4}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          {`${remainingTime}s`}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default NotificationDialogList;
