import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import {
  developerEmailState,
  developerIdTokenState,
  originalPathState,
  userInfoState,
} from "../global-state";
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  Typography,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import useApiCall from "../common/api-call";
import { getUserInfo } from "./setting-service";
import Scope from "./scope";
import Developer from "./developer";
import { Amplify, Auth } from "aws-amplify";
import { DEV_AUTH_CONFIG, USER_AUTH_CONFIG } from "../aws-config";

const Setting = () => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [activeScope, setActiveScope] = useState("");
  const [scopes, setScopes] = useState([]);
  const userInfoRef = useRef(userInfo);
  const theme = useTheme();
  const [selectedPage, setSelectedPage] = useState(0);
  const [isDefault, setIsDefault] = useState(true);
  const [originalPath, setOriginalPath] = useRecoilState(originalPathState);
  const path = useLocation();
  const { apiCall } = useApiCall();

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const routes = [
    { path: "/scope", title: "Scope" },
    { path: "/developer", title: "Developer" },
  ];

  const handleNavigation = (index) => {
    setIsDefault(false);
    setSelectedPage(index);
  };

  const fetchUserInfo = async () => {
    setOriginalPath(path.pathname + path.search + path.hash);
    const resp = await getUserInfo(apiCall, userInfo);
    if (resp) {
      setUserInfo(resp);
      setActiveScope(resp.activeScope);
      setScopes(resp.scopes);
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
      history.push("/user-login");
    }
  };

  const handleUserLoginLogout = () => {
    if (userLoggedIn) {
      handleUserLogout();
    } else {
      handleUserLogin();
    }
  };

  const handleUserLogin = async () => {
    setOriginalPath(path.pathname + path.search + path.hash);
    history.push("/user-login");
  };

  const handleUserLogout = async () => {
    try {
      Amplify.configure(USER_AUTH_CONFIG);
      await Auth.signOut();
      setOriginalPath(path.pathname + path.search + path.hash);
      history.push("/user-login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: isSmallScreen ? "0" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        {/* Sidebar */}
        {(!isSmallScreen || (isSmallScreen && isDefault)) && (
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "35%",
              maxWidth: isSmallScreen ? "100%" : 375,
              minHeight: "80vh",
              flexShrink: 0,
              p: 2,
              backgroundColor: isSmallScreen
                ? "transparent"
                : theme.palette.background.card,
            }}
          >
            <Box>
              {isSmallScreen && (
                <Box
                  position="static"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    Setting
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 2,
                  p: 2,
                }}
              >
                <Avatar
                  alt="User Name"
                  src="/path_to_avatar_image.jpg"
                  sx={{ width: 80, height: 80, mt: 3 }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    mt: 1,
                    color: theme.palette.text.gray,
                  }}
                >
                  {userLoggedIn ? userInfo.name : "Please login"}
                </Typography>
              </Box>
              <List>
                {routes.map((page, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleNavigation(index)}
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      borderBottom: "1px solid #222",
                      backgroundColor: theme.palette.background.card,
                    }}
                  >
                    <ListItemText primary={page.title} />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={() => {
                    handleUserLoginLogout();
                  }}
                  sx={{
                    textAlign: "center",
                    mb: 1,
                    borderBottom: "1px solid #222",
                    backgroundColor: theme.palette.background.card,
                  }}
                >
                  <ListItemText primary={userLoggedIn ? "Logout" : "Login"} />
                </ListItem>
              </List>
            </Box>
          </Box>
        )}

        {/* Content */}
        {(!isSmallScreen || (isSmallScreen && !isDefault)) && (
          <Box
            sx={{
              flexGrow: 1,
              minHeight: "80vh",
              backgroundColor: isSmallScreen
                ? "transparent"
                : theme.palette.background.card,
            }}
          >
            <Box>
              {selectedPage === 0 && (
                <>
                  <Scope isDefault={isDefault} setIsDefault={setIsDefault} />
                </>
              )}
              {selectedPage === 1 && (
                <>
                  <Developer
                    isDefault={isDefault}
                    setIsDefault={setIsDefault}
                  />
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Setting;
