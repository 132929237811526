import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Helmet } from "react-helmet";
import "@aws-amplify/ui-react/styles.css";
import DeveloperLogin from "./auth/developer-login";
import { useRecoilState } from "recoil";
import OAuthCallback from "./oauth-callback";
import { WebSocketProvider } from "./ws/websocket";
import { EventProvider } from "./ws/event-context";
import UserApp from "./views/user-app";
import { ToastProvider } from "./common/toast";
import { BackdropProvider } from "./common/backdrop";
import UserLogin from "./auth/user-login";
import UserLoginCallback from "./auth/user-login-callback";
import {
  linkOrgIdState,
  linkOrgUserIdState,
  platformState,
  userIdTokenState,
} from "./global-state";
import UserLink from "./auth/user-link";
import DeveloperLogout from "./auth/developer-logout";
import UserLogout from "./auth/user-logout";
import DeveloperLoginCallback from "./auth/developer-login-callback";

const PrivateRoute = ({ component: Component, authed, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default function App() {
  const [userIdToken, setUserIdToken] = useRecoilState(userIdTokenState);
  const [linkOrgId, setLinkOrgId] = useRecoilState(linkOrgIdState);
  const [linkOrgUserId, setLinkOrgUserId] = useRecoilState(linkOrgUserIdState);
  const [platform, setPlatform] = useRecoilState(platformState);

  const getSubtitle = () => {
    switch (process.env.NODE_ENV) {
      case "development":
        return "mHome AI user app - dev";
      case "staging":
        return "mHome AI user app - staging";
      default:
        return "mHome AI user app";
    }
  };

  useEffect(() => {
    const p = { source: "", config: {} };
    if (window.Android) {
      console.log("From Android!");

      window.syncAppData = () => {
        const token = window.Android.getIdToken();
        if (token) {
          setUserIdToken(token);
        }
      };

      const token = window.Android.getIdToken();
      if (token) {
        setUserIdToken(token);
      }
      const orgId = window.Android.getOrgId();
      const orgUserId = window.Android.getOrgUserId();
      if (orgId && orgUserId) {
        setLinkOrgId(orgId);
        setLinkOrgUserId(orgUserId);
      }
      p.source = "app";
      // Check if the domain matches and store `localServiceAddress` in `localStorage`
      const localServiceAddress = window.Android.getLocalServiceAddress();
      if (localServiceAddress) {
        localStorage.setItem("localServiceAddress", localServiceAddress);
      }
    } else {
      p.source = "web";
    }
    setPlatform(p);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>mHome AI</title>
        <meta name="description" content={getSubtitle()} />
      </Helmet>
      <BrowserRouter basename="/">
        <ToastProvider>
          <BackdropProvider>
            <EventProvider>
              <WebSocketProvider>
                {platform && (
                  <Switch>
                    <Route exact path="/developer-login">
                      <DeveloperLogin />
                    </Route>
                    <Route exact path="/developer-logout">
                      <DeveloperLogout />
                    </Route>
                    <Route exact path="/user-login">
                      <UserLogin />
                    </Route>
                    <Route exact path="/user-logout">
                      <UserLogout />
                    </Route>
                    <Route exact path="/user-link">
                      <UserLink />
                    </Route>
                    <Route exact path="/user-login-callback">
                      <UserLoginCallback />
                    </Route>
                    <Route exact path="/developer-login-callback">
                      <DeveloperLoginCallback />
                    </Route>
                    <Route exact path="/oauth">
                      <OAuthCallback />
                    </Route>
                    <PrivateRoute path="/" component={UserApp} />
                  </Switch>
                )}
              </WebSocketProvider>
            </EventProvider>
          </BackdropProvider>
        </ToastProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
