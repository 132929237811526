import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MSwitch from "../common/switch";

/**
 * {value: boolean}
 */
const BoolInput = ({ valueSpec, onChange }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(valueSpec.value || false);
    return () => {};
  }, [valueSpec]);

  const handleChange = (event) => {
    setValue(event.target.checked);
    onChange(event.target.checked.toString());
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {valueSpec.name && <Typography variant="h6">{valueSpec.name}</Typography>}
      <MSwitch
        checked={value}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

export default BoolInput;
