import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { USER_AUTH_CONFIG } from "../aws-config";
import { checkExternalLogin } from "./user-login-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";

const UserLoginCallback = () => {
  const history = useHistory();
  const { successToast, warningToast, errorToast } = useToast();
  const { apiCall } = useApiCall();

  // Check if the current domain is the development domain
  useEffect(() => {
    handleCallback();
  }, []);

  // Handle login and authentication
  const handleCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    const skipCheck = urlParams.get("skipCheck") === "true";

    if (!skipCheck) {
      try {
        Amplify.configure(USER_AUTH_CONFIG);
        const session = await Auth.currentSession();
        const email = session.getIdToken().payload["email"];
        const externalUsername = session.getAccessToken().payload["username"];
        const providerName = "Google";

        const resp = await checkExternalLogin(
          apiCall,
          email,
          providerName,
          externalUsername
        );
        if (resp) {
          if (resp.linked) {
            warningToast(`Account ${email} exists. Auto merged.`);
          }
        } else {
          throw new Error("login fail");
        }
      } catch (error) {
        console.error("Error during callback handling", error);
        errorToast("Sign-in Error!");
        history.push("/user-login");
      }
    }

    handleRedirect();
  };

  // Redirect to the desired path after confirming the service address
  const handleRedirect = () => {
    const path = new URLSearchParams(window.location.search).get("state");
    history.push(path ? decodeURIComponent(path) : "/");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1>Redirecting...</h1>
    </div>
  );
};

export default UserLoginCallback;
