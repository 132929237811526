import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useWebSocket } from "../ws/websocket";
import LoadingSpinner from "../common/loading";
import { useEventEmitter } from "../ws/event-context";
import InstructionInput from "../input/instruction-input";
import { useBackdrop } from "../common/backdrop";
import AutomationEditCardContent from "./automation-edit-card-content";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const OverviewConfigView = () => {
  const [instruction, setInstruction] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { ws } = useWebSocket();
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { subscribe } = useEventEmitter();
  const [params, setParams] = useState({});
  const [outdated, setOutdated] = useState(false);

  useEffect(() => {
    const { unsubscribe } = subscribe((target, data) => {
      if (target === "_closed_") {
        openBackdrop("Please Refresh");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const p = { draft: null };
    for (const [key, value] of searchParams.entries()) {
      if (key === "id") {
        p["automationId"] = value;
      } else {
        p[key] = value;
      }
    }

    if (ws && ws.readyState === WebSocket.OPEN) {
      closeBackdrop();
      setParams(p);
      setLoading(false);
    }
  }, [ws, ws?.readyState]);

  const handleAutomationSave = async (automationId) => {
    history.replace(`/automation/overview?id=${automationId}`);
    var newParams = {
      ...params,
      automationId,
      premadeId: null,
      premadeVersion: null,
      draft: null,
    };
    setParams(newParams);
  };

  const handleDelete = async () => {
    history.push("/automation/list");
  };

  const handleInstructionChange = (event) => {
    setInstruction(event.target.value);
  };

  const handleInstructionSend = async () => {
    // setTimeout(async () => {
    //     await loadAutomation('d6fe5180-c081-4f7c-9de8-277fe856b544');
    //     setUnsaved(true);
    //     setEnabled(false);
    // }, 3561);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: {
          xs: 56,
          sm: 64,
        },
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        zIndex: 1,
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
            }}
          >
            {params && params.mode === "new" ? (
              <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="h6" color="textSecondary">
                  Please enter your instruction
                </Typography>
              </Box>
            ) : (
              <Box sx={{ p: 3 }}>
                <Card
                  elevation={0}
                  sx={{
                    maxWidth: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: 3,
                    borderRadius: "8px",
                  }}
                >
                  <AutomationEditCardContent
                    params={params}
                    onSave={handleAutomationSave}
                    onDelete={handleDelete}
                    editable={true}
                  />
                </Card>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              borderTop: 1,
              borderColor: "divider",
              bgcolor: "background.paper", // Optional: ensures input box has solid background
            }}
          >
            <InstructionInput
              placeholder="Enter your instruction here..."
              sendName="Send"
              onSend={handleInstructionSend}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default OverviewConfigView;
